import { roles } from '../utility/roles';
import routes from '../utility/routes';

const accessControl =
{
    [roles[1]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.createUser]: ["read", "write"],
            [routes.editProfile]: ["read", "write"]
        },
        features: {
            documents: [],
            approvableDocuments: {
                PFI: [],
                SI: [],
                CI: [],
                SA: [],
                PL: [],
                BE: [],
                COO: []
            },
            businessUnits: []
        }
    },
    [roles[2]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealFertilizers]: ["read", "write"],
            [routes.dealFoods]: ["read", "write"],
            [routes.dealMiscellaneous]: ["read", "write"],
            [routes.dealPetrochemicals]: ["read", "write"],
            [routes.dealPolymers]: ["read", "write"],
            [routes.dealSBLC]: ["read", "write"],
            [routes.entityCounterParties]: ["read"],
            [routes.entitySBLC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read", "write"],
                SI: ["read", "write"],
                CI: ["read", "write"],
                SA: ["read", "write"],
                PL: ["read", "write"],
                BE: ["read", "write"],
                COO: ["read", "write"]
            },
            businessUnits: [
                {
                    id: 2,
                    value: "Fertilizers"
                },
                {
                    id: 4,
                    value: "Foods"
                },                
                {
                    id: 5,
                    value: "Miscellaneous"
                },
                {
                    id: 3,
                    value: "Petrochemicals/Feedstocks"
                },
                {
                    id: 1,
                    value: "Polymers"
                },
                {
                    id: 6,
                    value: "SBLC"
                }
            ]
        }
    },
    [roles[3]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealFertilizers]: ["read"],
            [routes.dealFoods]: ["read"],
            [routes.dealMiscellaneous]: ["read"],
            [routes.dealPetrochemicals]: ["read"],
            [routes.dealPolymers]: ["read"],
            [routes.dealSBLC]: ["read"],
            [routes.approveDocuments]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read"],
                SI: ["read"],
                CI: ["read"],
                SA: ["read"],
                PL: ["read"],
                BE: ["read"],
                COO: ["read"]
            },
            businessUnits: [
                {
                    id: 2,
                    value: "Fertilizers"
                },
                {
                    id: 4,
                    value: "Foods"
                },                
                {
                    id: 5,
                    value: "Miscellaneous"
                },
                {
                    id: 3,
                    value: "Petrochemicals/Feedstocks"
                },
                {
                    id: 1,
                    value: "Polymers"
                },
                {
                    id: 6,
                    value: "SBLC"
                }
            ]
        }
    },
    [roles[4]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealFertilizers]: ["read", "write"],
            [routes.dealMiscellaneous]: ["read", "write"],
            [routes.entityBanks]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read"],
            [routes.entityDealPFIs]: ["read"],
            [routes.entityProducts]: ["read"],
            [routes.entityTraders]: ["read"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read", "write"],
            [routes.approveDocuments]: ["read", "write"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read", "write"],
                SI: ["read", "write"],
                CI: ["read", "write"],
                SA: ["read", "write"],
                PL: ["read", "write"],
                BE: ["read", "write"],
                COO: ["read", "write"]
            },
            businessUnits: [
                {
                    id: 2,
                    value: "Fertilizers"
                },
                {
                    id: 5,
                    value: "Miscellaneous"
                }
            ]
        }    
    },
    [roles[5]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealFertilizers]: ["read", "write"],
            [routes.dealMiscellaneous]: ["read", "write"],
            [routes.entityBanks]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read", "write"],
            [routes.entityDealPFIs]: ["read", "write"],
            [routes.entityProducts]: ["read"],
            [routes.entityTraders]: ["read"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read", "write"],
            [routes.approveDocuments]: ["read", "write"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read", "write"],
                SI: ["read", "write"],
                CI: ["read", "write"],
                SA: ["read", "write"],
                PL: ["read", "write"],
                BE: ["read", "write"],
                COO: ["read", "write"]
            },
            businessUnits: [
                {
                    id: 2,
                    value: "Fertilizers"
                },
                {
                    id: 5,
                    value: "Miscellaneous"
                }
            ]
        }
    },
    [roles[6]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealFertilizers]: ["read"],
            [routes.dealMiscellaneous]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read"],
            [routes.entityDealPFIs]: ["read"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read"],
            [routes.approveDocuments]: ["read"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read"],
                SI: ["read"],
                CI: ["read"],
                SA: ["read"],
                PL: ["read"],
                BE: ["read"],
                COO: ["read"]
            },
            businessUnits: [
                {
                    id: 2,
                    value: "Fertilizers"
                },
                {
                    id: 5,
                    value: "Miscellaneous"
                }
            ]
        }
    },
    [roles[7]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealFertilizers]: ["read"],
            [routes.dealFoods]: ["read"],
            [routes.dealMiscellaneous]: ["read"],
            [routes.dealPetrochemicals]: ["read"],
            [routes.dealPolymers]: ["read"],
            [routes.dealSBLC]: ["read"],
            [routes.entityCounterParties]: ["read", "write"],
            [routes.entityDeals]: ["read"],
            [routes.entityDealPFIs]: ["read"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.approveDocuments]: ["read"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.kycCounterPartyList]: ["read", "write"],
            [routes.kycHistory]: ["read", "write"],
            [routes.kycTransactionHistory]: ["read", "write"],
            [routes.kycRatings]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read"],
                SI: ["read"],
                CI: ["read"],
                SA: ["read"],
                PL: ["read"],
                BE: ["read"],
                COO: ["read"]
            },
            businessUnits: [
                {
                    id: 2,
                    value: "Fertilizers"
                },
                {
                    id: 4,
                    value: "Foods"
                },                
                {
                    id: 5,
                    value: "Miscellaneous"
                },
                {
                    id: 3,
                    value: "Petrochemicals/Feedstocks"
                },
                {
                    id: 1,
                    value: "Polymers"
                },
                {
                    id: 6,
                    value: "SBLC"
                }
            ]
        }
    },
    [roles[8]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealFertilizers]: ["read"],
            [routes.dealFoods]: ["read"],
            [routes.dealMiscellaneous]: ["read"],
            [routes.dealPetrochemicals]: ["read"],
            [routes.dealPolymers]: ["read"],
            [routes.dealSBLC]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read"],
            [routes.entityDealPFIs]: ["read"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read"],
            [routes.approveDocuments]: ["read"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read"],
                SI: ["read"],
                CI: ["read"],
                SA: ["read"],
                PL: ["read"],
                BE: ["read"],
                COO: ["read"]
            },
            businessUnits: [
                {
                    id: 2,
                    value: "Fertilizers"
                },
                {
                    id: 4,
                    value: "Foods"
                },                
                {
                    id: 5,
                    value: "Miscellaneous"
                },
                {
                    id: 3,
                    value: "Petrochemicals/Feedstocks"
                },
                {
                    id: 1,
                    value: "Polymers"
                },
                {
                    id: 6,
                    value: "SBLC"
                }
            ]
        }
    },
    [roles[9]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.entityBanks]: ["read", "write"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDropdowns]: ["read", "write"],
            [routes.entityFileTypes]: ["read", "write"],
            [routes.entityProducts]: ["read", "write"],
            [routes.entityTenors]: ["read", "write"],
            [routes.entityTraders]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: [],
            approvableDocuments: {
                PFI: [],
                SI: [],
                CI: [],
                SA: [],
                PL: [],
                BE: [],
                COO: []
            },
            businessUnits: []
        }
    },
    [roles[10]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealFoods]: ["read", "write"],
            [routes.dealMiscellaneous]: ["read", "write"],
            [routes.entityBanks]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read"],
            [routes.entityDealPFIs]: ["read"],
            [routes.entityProducts]: ["read"],
            [routes.entityTraders]: ["read"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read"],
            [routes.approveDocuments]: ["read", "write"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read", "write"],
                SI: ["read", "write"],
                CI: ["read", "write"],
                SA: ["read", "write"],
                PL: ["read", "write"],
                BE: ["read", "write"],
                COO: ["read", "write"]
            },
            businessUnits: [
                {
                    id: 4,
                    value: "Foods"
                },
                {
                    id: 5,
                    value: "Miscellaneous"
                }
            ]
        }
    },
    [roles[11]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealFoods]: ["read", "write"],
            [routes.dealMiscellaneous]: ["read", "write"],
            [routes.entityBanks]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read", "write"],
            [routes.entityDealPFIs]: ["read", "write"],
            [routes.entityProducts]: ["read"],
            [routes.entityTraders]: ["read"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read"],
            [routes.approveDocuments]: ["read", "write"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read", "write"],
                SI: ["read", "write"],
                CI: ["read", "write"],
                SA: ["read", "write"],
                PL: ["read", "write"],
                BE: ["read", "write"],
                COO: ["read", "write"]
            },
            businessUnits: [
                {
                    id: 4,
                    value: "Foods"
                },
                {
                    id: 5,
                    value: "Miscellaneous"
                }
            ]
        }
    },
    [roles[12]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealFoods]: ["read"],
            [routes.dealMiscellaneous]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read"],
            [routes.entityDealPFIs]: ["read"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read"],
            [routes.approveDocuments]: ["read"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read"],
                SI: ["read"],
                CI: ["read"],
                SA: ["read"],
                PL: ["read"],
                BE: ["read"],
                COO: ["read"]
            },
            businessUnits: [
                {
                    id: 4,
                    value: "Foods"
                },
                {
                    id: 5,
                    value: "Miscellaneous"
                }
            ]
        }
    },
    [roles[13]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealMiscellaneous]: ["read", "write"],
            [routes.dealPolymers]: ["read", "write"],
            [routes.dealSBLC]: ["read", "write"],
            [routes.entityBanks]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read"],
            [routes.entityDealPFIs]: ["read"],
            [routes.entityProducts]: ["read"],
            [routes.entityTraders]: ["read"],
            [routes.entitySBLC]: ["read", "write"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read", "write"],
            [routes.approveDocuments]: ["read", "write"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read", "write"],
                SI: ["read", "write"],
                CI: ["read", "write"],
                SA: ["read", "write"],
                PL: ["read", "write"],
                BE: ["read", "write"],
                COO: ["read", "write"]
            },
            businessUnits: [
                {
                    id: 5,
                    value: "Miscellaneous"
                },
                {
                    id: 1,
                    value: "Polymers"
                },
                {
                    id: 6,
                    value: "SBLC"
                }
            ]
        }
    },
    [roles[14]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealMiscellaneous]: ["read", "write"],
            [routes.dealPolymers]: ["read", "write"],
            [routes.dealSBLC]: ["read", "write"],
            [routes.entityBanks]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read", "write"],
            [routes.entityDealPFIs]: ["read", "write"],
            [routes.entityProducts]: ["read"],
            [routes.entityTraders]: ["read"],
            [routes.entitySBLC]: ["read", "write"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read", "write"],
            [routes.approveDocuments]: ["read", "write"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read", "write"],
                SI: ["read", "write"],
                CI: ["read", "write"],
                SA: ["read", "write"],
                PL: ["read", "write"],
                BE: ["read", "write"],
                COO: ["read", "write"]
            },
            businessUnits: [
                {
                    id: 5,
                    value: "Miscellaneous"
                },
                {
                    id: 1,
                    value: "Polymers"
                },
                {
                    id: 6,
                    value: "SBLC"
                }
            ]
        }
    },
    [roles[15]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealMiscellaneous]: ["read"],
            [routes.dealPolymers]: ["read"],
            [routes.dealSBLC]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read"],
            [routes.entityDealPFIs]: ["read"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read"],
            [routes.approveDocuments]: ["read"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read"],
                SI: ["read"],
                CI: ["read"],
                SA: ["read"],
                PL: ["read"],
                BE: ["read"],
                COO: ["read"]
            },
            businessUnits: [
                {
                    id: 5,
                    value: "Miscellaneous"
                },
                {
                    id: 1,
                    value: "Polymers"
                },
                {
                    id: 6,
                    value: "SBLC"
                }
            ]
        }
    },
    [roles[16]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealMiscellaneous]: ["read", "write"],
            [routes.dealPetrochemicals]: ["read", "write"],
            [routes.entityBanks]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read"],
            [routes.entityDealPFIs]: ["read"],
            [routes.entityProducts]: ["read"],
            [routes.entityTraders]: ["read"],
            [routes.entitySBLC]: ["read", "write"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read", "write"],
            [routes.approveDocuments]: ["read", "write"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read", "write"],
                SI: ["read", "write"],
                CI: ["read", "write"],
                SA: ["read", "write"],
                PL: ["read", "write"],
                BE: ["read", "write"],
                COO: ["read", "write"]
            },
            businessUnits: [
                {
                    id: 5,
                    value: "Miscellaneous"
                },
                {
                    id: 3,
                    value: "Petrochemicals/Feedstocks"
                }
            ]
        }
    },
    [roles[17]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealMiscellaneous]: ["read", "write"],
            [routes.dealPetrochemicals]: ["read", "write"],
            [routes.entityBanks]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read", "write"],
            [routes.entityDealPFIs]: ["read", "write"],
            [routes.entityProducts]: ["read"],
            [routes.entityTraders]: ["read"],
            [routes.entitySBLC]: ["read", "write"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read", "write"],
            [routes.approveDocuments]: ["read", "write"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read", "write"],
                SI: ["read", "write"],
                CI: ["read", "write"],
                SA: ["read", "write"],
                PL: ["read", "write"],
                BE: ["read", "write"],
                COO: ["read", "write"]
            },
            businessUnits: [
                {
                    id: 5,
                    value: "Miscellaneous"
                },
                {
                    id: 3,
                    value: "Petrochemicals/Feedstocks"
                }
            ]
        }
    },
    [roles[18]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealMiscellaneous]: ["read"],
            [routes.dealPetrochemicals]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read"],
            [routes.entityDealPFIs]: ["read"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read"],
            [routes.approveDocuments]: ["read"],
            [routes.kycNewKYC]: ["read", "write"],
            [routes.kycRequests]: ["read", "write"],
            [routes.kycEditKYC]: ["read", "write"],
            [routes.editProfile]: ["read", "write"],
            [routes.pageNotFound]: ["read", "write"]
        },
        features: {
            documents: ["read", "write"],
            approvableDocuments: {
                PFI: ["read"],
                SI: ["read"],
                CI: ["read"],
                SA: ["read"],
                PL: ["read"],
                BE: ["read"],
                COO: ["read"]
            },
            businessUnits: [
                {
                    id: 5,
                    value: "Miscellaneous"
                },
                {
                    id: 3,
                    value: "Petrochemicals/Feedstocks"
                }
            ]
        }
    },
    [roles[19]]: {
        routes: {
            [routes.setNewPassword]: ["read", "write"],
            [routes.dealFertilizers]: ["read"],
            [routes.dealFoods]: ["read"],
            [routes.dealMiscellaneous]: ["read"],
            [routes.dealPetrochemicals]: ["read"],
            [routes.dealPolymers]: ["read"],
            [routes.dealSBLC]: ["read"],
            [routes.entityBanks]: ["read"],
            [routes.entityCounterParties]: ["read"],
            [routes.entityDeals]: ["read"],
            [routes.entityDealPFIs]: ["read"],
            [routes.entityDropdowns]: ["read"],
            [routes.entityFileTypes]: ["read"],
            [routes.entityProducts]: ["read"],
            [routes.entitySBLC]: ["read"],
            [routes.entityTenors]: ["read"],
            [routes.entityTraders]: ["read"],
            [routes.reportDealReport]: ["read"],
            [routes.reportFinanceReport]: ["read"],
            [routes.reportMorningReport]: ["read"],
            [routes.approveDocuments]: ["read"],
            [routes.kycRequests]: ["read"],
            [routes.kycCounterPartyList]: ["read"],
            [routes.kycHistory]: ["read"],
            [routes.kycTransactionHistory]: ["read"],
            [routes.kycRatings]: ["read"],
            [routes.editProfile]: ["read", "write"]
        },
        features: {
            documents: ["read"],
            approvableDocuments: {
                PFI: ["read"],
                SI: ["read"],
                CI: ["read"],
                SA: ["read"],
                PL: ["read"],
                BE: ["read"],
                COO: ["read"]
            },
            businessUnits: [
                {
                    id: 2,
                    value: "Fertilizers"
                },
                {
                    id: 4,
                    value: "Foods"
                },                
                {
                    id: 5,
                    value: "Miscellaneous"
                },
                {
                    id: 3,
                    value: "Petrochemicals/Feedstocks"
                },
                {
                    id: 1,
                    value: "Polymers"
                },
                {
                    id: 6,
                    value: "SBLC"
                }
            ]
        }
    }
};

export default accessControl;

export const checkPermission = (role, route, action) => 
{
    const roleAccess = accessControl[role];

    // Return false if role is not defined
    if (!roleAccess) 
    {
        return false;
    }

    // Return false if route is not defined for the role
    if (!roleAccess.routes[route])
    {
        return false;
    }

    // Return false if action is not allowed on the route
    if (!roleAccess.routes[route].includes(action)) 
    {
        return false;
    }

    return true;
};

const getRoutePermissions = (roleAccess, route) => 
{
    const permissions = roleAccess.routes?.[route] || [];

    return {
        isReadable: permissions.includes("read"),
        isWritable: permissions.includes("write")
    };
};

const getFeaturePermissions = (roleAccess, feature) => 
{
    if (feature === "approvableDocuments") 
    {
        const approvableDocuments = roleAccess.features?.approvableDocuments || {};
    
        return Object.entries(approvableDocuments).reduce((accumulator, [document, permissions]) => 
        {
            accumulator[document] = {
                isReadable: permissions.includes("read"),
                isWritable: permissions.includes("write")
            };

            return accumulator;
        }, {});
    } 
    else if (feature === "businessUnits") 
    {
        return roleAccess.features?.businessUnits || [];
    } 
    else if (feature === "documents") 
    {
        const documents = roleAccess.features?.documents || [];
    
        return {
            isReadable: documents.includes("read"),
            isWritable: documents.includes("write")
        };
    }

    return null;
};

export const getPermissions = (role, route = null, feature = null) => 
{
    const roleAccess = accessControl[role];

    // Return default permissions (none) if the role is undefined
    if (!roleAccess) 
    {
        return { isReadable: false, isWritable: false };
    }

    if (route) 
    {
        // Get permissions for the route
        return getRoutePermissions(roleAccess, route);
    } 
    else if (feature) 
    {
        // Get permissions for the feature
        return getFeaturePermissions(roleAccess, feature);
    }

    // Default return if neither route nor feature is provided
    return { isReadable: false, isWritable: false };
};