import { roles } from '../utility/roles';
import routes from '../utility/routes';

const routeControl = {
    login: {
        default: routes.login,
        [routes.login]: routes.login,
        [routes.resetPassword]: routes.resetPassword,
        [routes.changePassword]: routes.changePassword
    },
    [roles[1]]: {
        default: routes.createUser,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.createUser]: routes.createUser,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[2]]: {
        default: routes.dealFertilizers,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealFertilizers]: routes.dealFertilizers,
        [routes.dealFoods]: routes.dealFoods,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.dealPetrochemicals]: routes.dealPetrochemicals,
        [routes.dealPolymers]: routes.dealPolymers,
        [routes.dealSBLC]: routes.dealSBLC,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entitySBLC]: routes.entitySBLC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[3]]: {
        default: routes.dealFertilizers,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.editProfile]: routes.editProfile,
        [routes.dealFertilizers]: routes.dealFertilizers,
        [routes.dealFoods]: routes.dealFoods,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.dealPetrochemicals]: routes.dealPetrochemicals,
        [routes.dealPolymers]: routes.dealPolymers,
        [routes.dealSBLC]: routes.dealSBLC,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[4]]: {
        default: routes.dealFertilizers,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealFertilizers]: routes.dealFertilizers,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.entityBanks]: routes.entityBanks,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.entityProducts]: routes.entityProducts,
        [routes.entityTraders]: routes.entityTraders,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[5]]: {
        default: routes.dealFertilizers,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealFertilizers]: routes.dealFertilizers,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.entityBanks]: routes.entityBanks,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.entityProducts]: routes.entityProducts,
        [routes.entityTraders]: routes.entityTraders,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[6]]: {
        default: routes.dealFertilizers,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealFertilizers]: routes.dealFertilizers,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[7]]: {
        default: routes.dealFertilizers,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealFertilizers]: routes.dealFertilizers,
        [routes.dealFoods]: routes.dealFoods,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.dealPetrochemicals]: routes.dealPetrochemicals,
        [routes.dealPolymers]: routes.dealPolymers,
        [routes.dealSBLC]: routes.dealSBLC,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.kycCounterPartyList]: routes.kycCounterPartyList,
        [routes.kycHistory]: routes.kycHistory,
        [routes.kycTransactionHistory]: routes.kycTransactionHistory,
        [routes.kycRatings]: routes.kycRatings,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[8]]: {
        default: routes.dealFertilizers,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealFertilizers]: routes.dealFertilizers,
        [routes.dealFoods]: routes.dealFoods,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.dealPetrochemicals]: routes.dealPetrochemicals,
        [routes.dealPolymers]: routes.dealPolymers,
        [routes.dealSBLC]: routes.dealSBLC,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[9]]: {
        default: routes.entityBanks,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.entityBanks]: routes.entityBanks,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDropdowns]: routes.entityDropdowns,
        [routes.entityFileTypes]: routes.entityFileTypes,
        [routes.entityProducts]: routes.entityProducts, 
        [routes.entityTenors]: routes.entityTenors,
        [routes.entityTraders]: routes.entityTraders,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[10]]: {
        default: routes.dealFoods,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealFoods]: routes.dealFoods,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.entityBanks]: routes.entityBanks,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.entityProducts]: routes.entityProducts,
        [routes.entityTraders]: routes.entityTraders,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[11]]: {
        default: routes.dealFoods,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealFoods]: routes.dealFoods,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.entityBanks]: routes.entityBanks,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.entityProducts]: routes.entityProducts,
        [routes.entityTraders]: routes.entityTraders,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[12]]: {
        default: routes.dealFoods,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealFoods]: routes.dealFoods,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[13]]: {
        default: routes.dealPolymers,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.dealPolymers]: routes.dealPolymers,
        [routes.dealSBLC]: routes.dealSBLC,
        [routes.entityBanks]: routes.entityBanks,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.entityProducts]: routes.entityProducts,
        [routes.entitySBLC]: routes.entitySBLC,
        [routes.entityTraders]: routes.entityTraders,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[14]]: {
        default: routes.dealPolymers,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.dealPolymers]: routes.dealPolymers,
        [routes.dealSBLC]: routes.dealSBLC,
        [routes.entityBanks]: routes.entityBanks,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.entityProducts]: routes.entityProducts,
        [routes.entitySBLC]: routes.entitySBLC,
        [routes.entityTraders]: routes.entityTraders,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[15]]: {
        default: routes.dealPolymers,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.dealPolymers]: routes.dealPolymers,
        [routes.dealSBLC]: routes.dealSBLC,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[16]]: {
        default: routes.dealPetrochemicals,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.dealPetrochemicals]: routes.dealPetrochemicals,
        [routes.entityBanks]: routes.entityBanks,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.entityProducts]: routes.entityProducts,
        [routes.entitySBLC]: routes.entitySBLC,
        [routes.entityTraders]: routes.entityTraders,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[17]]: {
        default: routes.dealPetrochemicals,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.dealPetrochemicals]: routes.dealPetrochemicals,
        [routes.entityBanks]: routes.entityBanks,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.entityProducts]: routes.entityProducts,
        [routes.entitySBLC]: routes.entitySBLC,
        [routes.entityTraders]: routes.entityTraders,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[18]]: {
        default: routes.dealPetrochemicals,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.dealPetrochemicals]: routes.dealPetrochemicals,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycNewKYC]: routes.kycNewKYC,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycEditKYC]: routes.kycEditKYC,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    },
    [roles[19]]: {
        default: routes.dealFertilizers,
        [routes.setNewPassword]: routes.setNewPassword,
        [routes.dealFertilizers]: routes.dealFertilizers,
        [routes.dealFoods]: routes.dealFoods,
        [routes.dealMiscellaneous]: routes.dealMiscellaneous,
        [routes.dealPetrochemicals]: routes.dealPetrochemicals,
        [routes.dealPolymers]: routes.dealPolymers,
        [routes.dealSBLC]: routes.dealSBLC,
        [routes.entityBanks]: routes.entityBanks,
        [routes.entityCounterParties]: routes.entityCounterParties,
        [routes.entityDeals]: routes.entityDeals,
        [routes.entityDealPFIs]: routes.entityDealPFIs,
        [routes.entityDropdowns]: routes.entityDropdowns,
        [routes.entityFileTypes]: routes.entityFileTypes,
        [routes.entityProducts]: routes.entityProducts, 
        [routes.entitySBLC]: routes.entitySBLC,
        [routes.entityTenors]: routes.entityTenors,
        [routes.entityTraders]: routes.entityTraders,
        [routes.reportDealReport]: routes.reportDealReport,
        [routes.reportFinanceReport]: routes.reportFinanceReport,
        [routes.reportMorningReport]: routes.reportMorningReport,
        [routes.approveDocuments]: routes.approveDocuments,
        [routes.kycRequests]: routes.kycRequests,
        [routes.kycCounterPartyList]: routes.kycCounterPartyList,
        [routes.kycHistory]: routes.kycHistory,
        [routes.kycTransactionHistory]: routes.kycTransactionHistory,
        [routes.kycRatings]: routes.kycRatings,
        [routes.editProfile]: routes.editProfile,
        [routes.pageNotFound]: routes.pageNotFound
    }
};

export default routeControl;

export const getDefaultPage = (role) => 
{
    const roleAccess = routeControl[role];
    return roleAccess?.default || null;
};