const routes = {
    login: "/login",
    setNewPassword: "/set-new-password",
    resetPassword: "/reset-password",
    changePassword: "/change-password/user/:id",
    dealFertilizers: "/deals/fertilizers",
    dealFoods: "/deals/foods",
    dealMiscellaneous: "/deals/miscellaneous",
    dealPetrochemicals: "/deals/petrochemicals/feedstocks",
    dealPolymers: "/deals/polymers",
    dealSBLC: "/deals/sblc",
    entityBanks: "/entities/banks",
    entityCounterParties: "/entities/counter-parties",
    entityDeals: "/entities/deals",
    entityDealPFIs: "/entities/deal-pfis",
    entityDropdowns: "/entities/dropdowns",
    entityFileTypes: "/entities/file-types",
    entityProducts: "/entities/products",
    entitySBLC: "/entities/sblc",
    entityTenors: "/entities/tenors",
    entityTraders: "/entities/traders",
    reportDealReport: "/reports/deal-report",
    reportFinanceReport: "/reports/finance-report",
    reportMorningReport: "/reports/morning-report",
    approveDocuments: "/approve-documents",
    kycNewKYC: "/kyc/new-kyc",
    kycRequests: "/kyc/requests",
    kycEditKYC: "/kyc/edit-kyc",
    kycCounterPartyList: "/kyc/counter-party-list",
    kycHistory: "/kyc/history",
    kycTransactionHistory: "/kyc/transaction-history",
    kycRatings: "/kyc/ratings",
    createUser: "/create-user",
    editProfile: "/edit-profile",
    pageNotFound: "*"
};

export default routes;

export const allowedRoutesByRole = {
    [process.env.REACT_APP_ROLE_SECRET_KEY_1]: [
        "/create-user",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_2]: [
        "/deals/fertilizers",
        "/deals/foods",
        "/deals/miscellaneous",
        "/deals/petrochemicals/feedstocks",
        "/deals/polymers",
        "/deals/sblc",
        "/entities/counter-parties",
        "/entities/sblc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_3]: [
        "/deals/fertilizers",
        "/deals/foods",
        "/deals/miscellaneous",
        "/deals/petrochemicals/feedstocks",
        "/deals/polymers",
        "/deals/sblc",
        "/approve-documents",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_4]: [
        "/deals/fertilizers",
        "/deals/miscellaneous",
        "/entities/banks",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/entities/products",
        "/entities/traders",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_5]: [
        "/deals/fertilizers",
        "/deals/miscellaneous",
        "/entities/banks",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/entities/products",
        "/entities/traders",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_6]: [
        "/deals/fertilizers",
        "/deals/miscellaneous",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_7]: [
        "/deals/fertilizers",
        "/deals/foods",
        "/deals/miscellaneous",
        "/deals/petrochemicals/feedstocks",
        "/deals/polymers",
        "/deals/sblc",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/reports/deal-report",
        "/reports/finance-report",
        "/approve-documents",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/kyc/counter-party-list",
        "/kyc/history",
        "/kyc/transaction-history",
        "/kyc/ratings",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_8]: [
        "/deals/fertilizers",
        "/deals/foods",
        "/deals/miscellaneous",
        "/deals/petrochemicals/feedstocks",
        "/deals/polymers",
        "/deals/sblc",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_9]: [
        "/entities/banks",
        "/entities/counter-parties",
        "/entities/dropdowns",
        "/entities/file-types",
        "/entities/products",
        "/entities/tenors",
        "/entities/traders",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_10]: [
        "/deals/foods",
        "/deals/miscellaneous",
        "/entities/banks",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/entities/products",
        "/entities/traders",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_11]: [
        "/deals/foods",
        "/deals/miscellaneous",
        "/entities/banks",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/entities/products",
        "/entities/traders",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_12]: [
        "/deals/foods",
        "/deals/miscellaneous",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_13]: [
        "/deals/miscellaneous",
        "/deals/polymers",
        "/deals/sblc",
        "/entities/banks",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/entities/products",
        "/entities/sblc",
        "/entities/traders",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_14]: [
        "/deals/miscellaneous",
        "/deals/polymers",
        "/deals/sblc",
        "/entities/banks",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/entities/products",
        "/entities/sblc",
        "/entities/traders",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_15]: [
        "/deals/miscellaneous",
        "/deals/polymers",
        "/deals/sblc",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_16]: [
        "/deals/miscellaneous",
        "/deals/petrochemicals/feedstocks",
        "/entities/banks",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/entities/products",
        "/entities/sblc",
        "/entities/traders",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_17]: [
        "/deals/miscellaneous",
        "/deals/petrochemicals/feedstocks",
        "/entities/banks",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/entities/products",
        "/entities/sblc",
        "/entities/traders",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_18]: [
        "/deals/miscellaneous",
        "/deals/petrochemicals/feedstocks",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/new-kyc",
        "/kyc/requests",
        "/kyc/edit-kyc",
        "/edit-profile"
    ],
    [process.env.REACT_APP_ROLE_SECRET_KEY_19]: [
        "/deals/fertilizers",
        "/deals/foods",
        "/deals/miscellaneous",
        "/deals/petrochemicals/feedstocks",
        "/deals/polymers",
        "/deals/sblc",
        "/entities/banks",
        "/entities/counter-parties",
        "/entities/deals",
        "/entities/deal-pfis",
        "/entities/dropdowns",
        "/entities/file-types",
        "/entities/products",
        "/entities/sblc",
        "/entities/tenors",
        "/entities/traders",
        "/reports/deal-report",
        "/reports/finance-report",
        "/reports/morning-report",
        "/approve-documents",
        "/kyc/requests",
        "/kyc/counter-party-list",
        "/kyc/history",
        "/kyc/transaction-history",
        "/kyc/ratings",
        "/edit-profile"
    ]
};

export const getRoutesByRole = (role) => 
{
    return allowedRoutesByRole[role] || [];
};