import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { roles } from '../../utility/roles';
import { getPermissions } from '../../lib/accessControl';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import withAuth from '../../lib/withAuth';
import useSessionExpire from '../../hooks/useSessionExpire';
import useUser from '../../hooks/useUser';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import CountdownTimer from '../../components/CountdownTimer';
import FileUploader from '../../components/FileUploader';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '../../components/Alert';
import Form from '../../components/Form';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import axios from 'axios';

const EditKYC = ({ logOut = () => {} }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const { isCounterPartyListItem = false, kycId = null } = location.state || {};

    /* AUTHORIZATION VARIABLES */
    const currentUser = useUser();
    const { isWritable = false } = getPermissions(currentUser?.role, location?.pathname);
    const { isWritable: isDocumentWritable = false } = getPermissions(currentUser?.role, null, "documents");
    const isGRCRole = currentUser?.role === roles[7];

    /* SESSION EXPIRY VARIABLES */
    const triggerSessionExpire = useSessionExpire();

    /* LIST VARIABLES */
    const [dropdowns, setDropdowns] = useState({});
    const [riskClassifications, setRiskClassifications] = useState([]);

    /* COUNTER PARTY VARIABLES */
    const [isCounterPartyDataLoading, setIsCounterPartyDataLoading] = useState(false);
    const [counterPartyFields, setCounterPartyFields] = useState([]);
    const [counterPartyData, setCounterPartyData] = useState([]);
    const [counterParties, setCounterParties] = useState([]);
    const [toggleCounterPartyData, setToggleCounterPartyData] = useState(false);;

    /* FORM VARIABLES */
    const dataSchema = {
        Registered_Company_Name: null,
        Registered_Business_Address: "",
        Country_of_Incorporation: "",
        Key_Contact_Person_Name: "",
        Key_Contact_Person_Designation: "",
        Key_Contact_Person_Mobile: "",
        Key_Contact_Person_Email: "",
        Business_Partner_Additional_Info: "",
        Original_Business_Relation_Nature: [],
        Business_Relation_Nature: [],
        Documentation_Exceptions: "",
        Advance_Payment_To_Counterparty: "",
        Lock_Date: null,
        GRC_Counter_Party_Type: null,
        GRC_Specific_Details: []
    };
    const [formData, setFormData] = useState(dataSchema);
    const [isEditable, setIsEditable] = useState(true);
    const formRef = useRef(null);
    const submitButtonRef = useRef(null);

    /* FILE VARIABLES */
    const [kycFileTypes, setKYCFileTypes] = useState([]);
    const [kycFiles, setKYCFiles] = useState([]);
    const [grcKYCFiles, setGRCKYCFiles] = useState([]);
    const [uploadedKYCFiles, setUploadedKYCFiles] = useState([]);

    /* TIMER VARIABLES */
    const [timeLeft, setTimeLeft] = useState({});
    const isTimeLeft = Object.keys(timeLeft).length > 0;
    
    /* MODAL VARIABLES */
    const [showAddCounterPartyModal, setShowAddCounterPartyModal] = useState(false);

    useEffect(() => 
    {
        if (!kycId) 
        {
            // If 'kycId' is missing, redirect to 'Requests' page
            navigate(isCounterPartyListItem ? "/kyc/counter-party-list" : "/kyc/requests");
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [kycId]);

    useEffect(() =>
    {
        const fetchLists = () =>
        {
            axios({
                method: "post",
                url: "/GetExternalKYCFileTypeList",
                data: { 
                    pathname: location?.pathname
                }
            })
            .then((response) => 
            {
                const { status, data } = response;
        
                if (status === 200) 
                {
                    setKYCFileTypes(data?.external_file_types || []);  
                }
            })
            .catch((error) => 
            {
                console.log("Get External File Type List Api: ", error);
                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });

            axios({
                method: "post",
                url: "/GetBusinessUnitList",
                data: { 
                    pathname: location?.pathname
                }
            })
            .then((response) => 
            {
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const businessUnits = data?.business_units || [];
                    setDropdowns((previousDropdowns) => ({
                        ...previousDropdowns,
                        Business_Unit: businessUnits
                    }));
                }
            })
            .catch((error) => 
            {
                console.log("Get Business Units Api: ", error);
                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });

            axios({
                method: "get",
                url: "/GetKYCRiskClassificationList"
            })
            .then((response) => 
            {
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const riskClassifications = data?.kyc_risk_classifications || [];
                    setRiskClassifications(riskClassifications);
                }
            })
            .catch((error) => 
            {
                console.log("Get KYC Risk Classifications Api: ", error);
                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }

        const fetchSelectedKYCData = async () => 
        {
            setIsLoading(true);
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");
            
            await axios({
                method: "post",
                url: isGRCRole ? "/GetKYCGRCRecord" : "/GetKYCRecord",
                data: {
                    uuid: kycId,
                    is_counter_party_list_item: isCounterPartyListItem
                }
            })
            .then((response) => 
            {
                const { status, data = {} } = response;
            
                if (status === 200) 
                {
                    const {
                        is_submitted_by_grc_customer: isCustomerSubmittedByGRC = 0,
                        is_submitted_by_grc_supplier: isSupplierSubmittedByGRC = 0,
                        is_submitted_by_grc: isSubmittedByGRC = 0,
                        Business_Relation_Nature: businessRelationNature = [],
                        lock_date: formLockDate = null
                    } = data;
                
                    const getFilteredNature = (type) => JSON.stringify(businessRelationNature.filter((nature) => nature === type));
                
                    data.Original_Business_Relation_Nature = isCustomerSubmittedByGRC && isSupplierSubmittedByGRC
                        ? JSON.stringify(businessRelationNature)
                        : isCustomerSubmittedByGRC
                        ? getFilteredNature("Customer")
                        : isSupplierSubmittedByGRC
                        ? getFilteredNature("Supplier")
                        : JSON.stringify([]);                  

                    const isCustomer = businessRelationNature.includes("Customer");
                    const isSupplier = businessRelationNature.includes("Supplier");
                
                    if (isSubmittedByGRC && isCustomer && isSupplier) 
                    {
                        setIsEditable(false);
                    }
                    else 
                    {
                        const counterPartyType = [
                            ...(isSupplier
                                ? isSubmittedByGRC
                                    ? ["Customer"]
                                    : ["Supplier", "Customer"]
                                : []),
                            ...(isCustomer
                                ? isSubmittedByGRC
                                    ? ["Supplier"]
                                    : ["Supplier", "Customer"]
                                : [])
                        ];
                
                        data.counter_party_type = [...new Set(counterPartyType)];
                    }

                    if (formLockDate)
                    {
                        data.Lock_Date = dayjs(formLockDate).add(10, "minute").format("YYYY-MM-DDTHH:mm:ss");
                    }
                
                    setFormData(data);  
                } 
                else 
                {
                    setIsLoading(false);
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get Selected KYC Data Api: ", error);
                setIsLoading(false);

                const status = error?.response?.status;

                if (status === 403) 
                {
                    triggerSessionExpire();
                }
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);

                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });

            await axios({
                method: "post",
                url: "/GetDocuments",
                data: {
                    Pathname: location?.pathname,
                    Doc_Type: "KYC_Documentation_And_Requests",
                    GRC_Doc_Type: isGRCRole ? "KYC_GRC_Documentation_And_Requests" : null,
                    KYC_Id: kycId
                }
            })
            .then((response) => 
            {
                setIsLoading(false);
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const files = data?.map(({ 
                        File_Name: name, 
                        File_Id: id, 
                        File_Location: location, 
                        File_Type: fileType,
                        Doc_Type: docName
                    }) => ({
                        file: { name, id, location, fileType },
                        docName
                    })) || [];
                    
                    if (isGRCRole)
                    {
                        const kycFiles = files.filter(file => file.docName === "KYC_Documentation_And_Requests") || [];
                        const grcKYCFiles = files.filter(file => file.docName === "KYC_GRC_Documentation_And_Requests") || [];
                        
                        setKYCFiles(kycFiles);
                        setGRCKYCFiles(grcKYCFiles);
                    }
                    else
                    {
                        setKYCFiles(files);
                    }
                } 
                else if (status === 400)
                {
                    setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
                else 
                {
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get KYC Documents Api: ", error);
                setIsLoading(false);

                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }

        fetchLists();
        fetchSelectedKYCData();

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() =>
    {
        const fetchCounterPartyLists = async () =>
        {
            setIsCounterPartyDataLoading(true);

            await axios({
                method: "post",
                url: "/GetVendorsKYC",
                data: { 
                    pathname: location?.pathname 
                }
            })
            .then((response) => 
            {
                const { status, data } = response;
                
                if (status === 200) 
                {
                    const counterPartyData = data?.vendor_data || [];
                    const { rows: rowData = [], columns = [], data_types: dataTypes = {} } = counterPartyData;
                    const columnData = columns.filter((column) => !(["id", "isDeleted"]).includes(column)) || [];
                    const tempColumns = [];

                    for (let i = 0; i < columnData.length; i++) 
                    {
                        if (dataTypes[columnData[i]].includes("object") || dataTypes[columnData[i]].includes("date")) 
                        {
                            tempColumns.push({
                                field: columnData[i],
                                type: 'string'
                            });
                        } 
                        else if (dataTypes[columnData[i]] !== "object") 
                        {
                            tempColumns.push({
                                field: columnData[i],
                                type: 'number'
                            });
                        }
                    }      

                    setCounterPartyFields(tempColumns);
                    setCounterPartyData(rowData);
                }  
                else 
                {
                    setIsCounterPartyDataLoading(false);
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get Counter Party Data Api: ", error);
                setIsCounterPartyDataLoading(false);

                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });

            await axios({
                method: "post",
                url: "/GetVendorListKYC",
                data: { 
                    pathname: location?.pathname 
                }
            })
            .then((response) => 
            {
                setIsCounterPartyDataLoading(false);
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const counterParties = data?.vendors || [];
                    setCounterParties(counterParties);
                }
            })
            .catch((error) => 
            {
                console.log("Get Filtered Sellers/Counter Parties Api: ", error);
                setIsCounterPartyDataLoading(false);

                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }

        fetchCounterPartyLists();
        
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [toggleCounterPartyData]);

    const convertToDayJsObject = (date) => 
    {
        const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
        return convertedDate;
    }

    const handleChange = (field, value) => 
    {    
        setFormData((previousFormData) => ({
            ...previousFormData,
            [field]: typeof value === "string" ? (value.trim() === "" ? null : value) : value ? value : null
        }));
    }

    const handleGRCChange = (index, field, value, isDate = false) => 
    {    
        setFormData(previousFormData => 
        {
            const newValue = isDate && value ? dayjs(value).format("MM/DD/YYYY") : value;
            const updatedGRCSpecificDetails = [...previousFormData.GRC_Specific_Details];
            const updatedGRCSpecificDetail = { ...updatedGRCSpecificDetails[index] };
            const requiredFields = ["Risk_Classification", "KYC_Status", "KYC_Execution", "Comments"];
        
            if (field === "Risk_Classification") 
            {
                const originalReviewDate = dayjs(formData?.originalNextReview || formData?.Next_Review || dayjs());
                const updatedReviewDate = originalReviewDate.add(newValue?.months || 0, "month").format("MM/DD/YYYY");
        
                updatedGRCSpecificDetail[field] = typeof newValue === "string" ? (newValue.trim() === "" ? null : newValue) : newValue || null;
                updatedGRCSpecificDetail.Next_Review = updatedReviewDate;
                updatedGRCSpecificDetail.originalNextReview = originalReviewDate.format("MM/DD/YYYY");
            } 
            else 
            {
                updatedGRCSpecificDetail[field] = typeof newValue === "string" ? (newValue.trim() === "" ? null : newValue) : newValue || null;
            }
        
            const hasValue = requiredFields.some(field => Boolean(updatedGRCSpecificDetail[field]));

            updatedGRCSpecificDetail["Is_Required"] = hasValue && updatedGRCSpecificDetail["KYC_Execution"] !== "Reverted Back"; 
            updatedGRCSpecificDetails[index] = updatedGRCSpecificDetail;
        
            return {
                ...previousFormData,
                GRC_Specific_Details: updatedGRCSpecificDetails
            };
        });        
    }
    
    const handleFileUpload = async (id) => 
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
        
        const fileFormData = new FormData();
        
        uploadedKYCFiles.forEach((file, index) => 
        {
            const fileObj = {
                DealPFIId: id,
                fileName: file.name,
                docType: isGRCRole ? "KYC_GRC_Documentation_And_Requests" : "KYC_Documentation_And_Requests",
                fileExtension: file.name.split(".").pop(),
                fileType: file.docType
            };
            
            fileFormData.append(`file${index}`, JSON.stringify(fileObj));
            fileFormData.append(`file${index}`, file);
        });    
    
        fileFormData.append("pathname", location?.pathname);

        await axios({
            method: "post",
            url: "/UploadDocument",
            data: fileFormData
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
        
            if (status === 200)
            {
                formRef?.current?.reset();
                
                setAlertMessage("KYC request form submitted successfully.");
                setAlertType("success");
                setShowAlert(true);
                setTimeout(() => handleBackPress(), 3000);
            }
            else if (status === 202)
            {
                setAlertMessage(data?.data || "An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);        
            }
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Upload KYC Documents Api: ", error);
            setIsLoading(false);
            
            const status = error?.response?.status;
        
            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleAddCounterParty = async (newCounterParty) =>
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
    
        await axios({
            method: "post",
            url: "/AddVendor",
            data: {
                pathname: location?.pathname,
                vendor_data: newCounterParty
            }
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
        
            if (status === 200) 
            {
                handleCloseModal();
                setAlertMessage("New counter party added successfully.");
                setAlertType("success");
                setShowAlert(true);
                setTimeout(() => setToggleCounterPartyData(!toggleCounterPartyData), 2000);
            } 
            else if (status === 202)
            {
                setAlertMessage(data?.data);
                setAlertType("error");
                setShowAlert(true);
            }
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Add Counter Party Api: ", error);
            setIsLoading(false);
            
            const status = error?.response?.status;
        
            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }    

    const handleSubmit = async (event) =>
    {
        event.preventDefault();

        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
    
        const updatedGRCDetails = formData?.GRC_Specific_Details?.map(detail => ({
            ...detail,
            Next_Review: dayjs(detail?.Next_Review).format("YYYY-MM-DD")
        }));

        let updatedFormData = { 
            ...formData,
            GRC_Specific_Details: updatedGRCDetails,
            User_Id: currentUser?.id,
            pathname: location?.pathname
        };
    
        updatedFormData = Object.fromEntries(Object.entries(updatedFormData).map(([key, value]) => [
            key,
            key === "Risk_Classification" ? value : typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
        ]));
        
        await axios({
            method: "post",
            url: isGRCRole ? "/UpdateKYCGRC" : "/UpdateKYC",
            data: updatedFormData
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
        
            if (status === 200) 
            {
                if (uploadedKYCFiles.length > 0) 
                {
                    handleFileUpload(data?.id);
                } 
                else 
                {
                    formRef?.current?.reset();

                    setAlertMessage("KYC request form submitted successfully.");
                    setAlertType("success");
                    setShowAlert(true);
                    setTimeout(() => handleBackPress(), 3000);
                }
            } 
            else if (status === 202)
            {
                setAlertMessage(data?.data);
                setAlertType("error");
                setShowAlert(true);
            }
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Add KYC Api: ", error);
            setIsLoading(false);
            
            const status = error?.response?.status;
        
            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleCloseModal = () => 
    {
        setShowAddCounterPartyModal(false);
    }

    const handleBackPress = () =>
    {
        navigate(-1);
    }

    return (
        <div className = "content-container d-flex flex-column container kyc position-relative">
            <Alert
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />

            <Backdrop
                sx = {{ color: "#fff", position: "absolute", zIndex: 2 }}
                open = {isLoading}
            >
                <CircularProgress color = "inherit" />
            </Backdrop>

            <div className = "d-flex justify-content-between align-items-center">
                <div className = "d-flex justify-content-start align-items-center gap-2 mb-2">
                    <IconButton color = "primary" sx = {{ boxShadow: 3 }} onClick = {handleBackPress}>
                        <ArrowBackIcon fontSize = "medium" />
                    </IconButton>

                    <h3 className = "m-0">Edit KYC</h3>
                </div>

                {(!isGRCRole && (formData?.Lock_Date || isTimeLeft)) && (
                    <CountdownTimer 
                        label = "Editing disabled until GRC review completes."
                        targetDate = {formData.Lock_Date}
                        timeLeft = {timeLeft}
                        setTimeLeft = {setTimeLeft} 
                    />
                )}
            </div>

            <form ref = {formRef} className = "kyc-form" onSubmit = {handleSubmit}>
                <div className = "d-flex align-items-center justify-content-between mt-1 mb-3">
                    <h3 className = "mb-0">Company Information</h3>

                    <Button
                        variant = "contained"
                        size = "small"
                        disabled = {!isWritable || isGRCRole || isCounterPartyDataLoading || isTimeLeft}
                        onClick = {() => setShowAddCounterPartyModal(true)}
                    >
                        Add Counter Party
                    </Button>
                </div>

                <Grid
                    container
                    spacing = {2}
                    className = "align-items-start autocomplete"
                >
                    <Grid size = {{ xs: 4 }}>
                        <VirtualizedAutocomplete
                            isLoading = {isCounterPartyDataLoading}
                            isMultiple = {false}
                            isObject = {true}
                            isWritable = {false}
                            isDisabled = {true}
                            filterOn = "Registered Name (Company)"
                            options = {counterParties}
                            selectedOptions = {formData?.Registered_Company_Name || null}
                            handleSelectChange = {(filterOn, newValue) => handleChange("Registered_Company_Name", newValue)}
                        />
                    </Grid>

                    <Grid size = {{ xs: 4 }} className = "form-text-field">
                        <TextField
                            label = "Registered Business Address"
                            id = "Registered_Business_Address"
                            value = {counterPartyData?.find((counterParty) => parseInt(counterParty.id) === parseInt(formData?.Registered_Company_Name?.id))?.Address || ""}
                            disabled = {true}
                            slotProps = {{
                                inputLabel: {
                                    shrink: counterPartyData?.some((counterParty) => parseInt(counterParty.id) === parseInt(formData?.Registered_Company_Name?.id))
                                }
                            }}
                        />
                    </Grid>

                    <Grid size = {{ xs: 4 }} className = "form-text-field">
                        <TextField
                            label = "Country of Incorporation"
                            id = "Country_of_Incorporation"
                            value = {counterPartyData?.find((counterParty) => parseInt(counterParty.id) === parseInt(formData?.Registered_Company_Name?.id))?.Country || ""}
                            disabled = {true}
                            slotProps = {{
                                inputLabel: {
                                    shrink: counterPartyData?.some((counterParty) => parseInt(counterParty.id) === parseInt(formData?.Registered_Company_Name?.id))
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                <h3>Business Relationship Details</h3>
                <Grid
                    container
                    spacing = {2}
                    className = "align-items-start autocomplete"
                >
                     <Grid size = {{ xs: 4 }}>
                        <VirtualizedAutocomplete
                            isMultiple = {true}
                            isObject = {false}
                            isWritable = {isWritable && !isGRCRole}
                            isRequired = {true}
                            isDisabled = {isTimeLeft}
                            filterOn = "Nature of Business Relation"
                            options = {["Customer", "Supplier"]}
                            originalSelectedOptions = {formData?.Original_Business_Relation_Nature}
                            selectedOptions = {formData?.Business_Relation_Nature || []}
                            handleSelectChange = {(filterOn, newValue) => handleChange("Business_Relation_Nature", newValue)}
                        />
                    </Grid>
                </Grid>

                <h3>Documentation & Requests</h3>
                <Grid
                    container
                    spacing = {2}
                    className = "align-items-start"
                >
                    <Grid size = {{ xs: 4 }} className = "form-text-field">
                        <TextField
                            label = "Documentation Exceptions Being Requested"
                            id = "Documentation_Exceptions"
                            value = {formData.Documentation_Exceptions || ""}
                            onChange = {(event) => handleChange("Documentation_Exceptions", event.target.value)}
                            disabled = {isGRCRole || isTimeLeft}
                            slotProps = {{
                                input: {
                                    readOnly: !isWritable || isGRCRole
                                }
                            }}
                        />
                    </Grid>

                    <Grid size = {{ xs: 12 }} className = "form-text-field">
                        <FileUploader
                            isWritable = {isDocumentWritable && !isGRCRole && !isTimeLeft}
                            isLoading = {isLoading}
                            isMultiple = {true}
                            isKYC = {true}
                            pathname = {location?.pathname}
                            uploadText = "Please upload customer response email to our KYC request."
                            files = {kycFiles}
                            uploadedFiles = {isGRCRole ? [] : uploadedKYCFiles}
                            allowFileTypeSelection = {true}
                            kycFileTypes = {kycFileTypes}
                            setIsLoading = {setIsLoading}
                            setShowAlert = {setShowAlert}
                            setAlertMessage = {setAlertMessage}
                            setAlertType = {setAlertType}
                            setFiles = {setKYCFiles}
                            setUploadedFiles = {isGRCRole ? () => {} : setUploadedKYCFiles}
                            logOut = {logOut}
                        />
                    </Grid>
                </Grid>

                <h3>Other Information</h3>
                <Grid
                    container
                    spacing = {2}
                    className = "align-items-start mb-1"
                >
                    <Grid size = {{ xs: 4 }} className = "form-text-field">
                        <TextField
                            label = "Are we paying any advance to counterparty?"
                            id = "Advance_Payment_To_Counterparty"
                            value = {formData.Advance_Payment_To_Counterparty || ""}
                            onChange = {(event) => handleChange("Advance_Payment_To_Counterparty", event.target.value)}
                            disabled = {isGRCRole || isTimeLeft}
                            slotProps = {{
                                input: {
                                    readOnly: !isWritable || isGRCRole
                                }
                            }}
                        />
                    </Grid>

                    <Grid size = {{ xs: 12 }} className = "form-text-field">
                        <TextField
                            label = "Any other important information regarding business partner?"
                            id = "Business_Partner_Additional_Info"
                            multiline
                            rows = {3}
                            value = {formData.Business_Partner_Additional_Info || ""}
                            onChange = {(event) => handleChange("Business_Partner_Additional_Info", event.target.value)}
                            disabled = {isGRCRole || isTimeLeft}
                            slotProps = {{
                                input: {
                                    readOnly: !isWritable || isGRCRole
                                }
                            }}
                        />
                    </Grid>
                </Grid>

                {isGRCRole && (
                    <>
                        <h3>GRC-Specific Details</h3>

                        {isLoading && formData?.GRC_Specific_Details?.length === 0 ? (
                            <div className = "skeleton-container">
                                <Skeleton variant = "rectangular" />
                                <Skeleton variant = "rectangular" />
                                <Skeleton variant = "rectangular" />
                                <Skeleton variant = "rectangular" />
                                <Skeleton variant = "rectangular" />
                            </div>
                        ) : (
                            <>
                                {formData?.GRC_Specific_Details?.map((grcFormData, index) => (
                                    <div key = {index}>
                                        <div className = "d-flex gap-2 my-2">
                                            <h4>Counter Party Type: {grcFormData?.Counter_Party_Type || ""}</h4>
                                        </div>

                                        <Grid
                                            container
                                            spacing = {2}
                                            className = "align-items-start autocomplete mb-3"
                                        >
                                            <Grid size = {{ xs: 4 }}>
                                                <VirtualizedAutocomplete
                                                    isMultiple = {false}
                                                    isObject = {true}
                                                    isWritable = {isWritable}
                                                    isRequired = {grcFormData?.Is_Required}
                                                    isDisabled = {grcFormData?.Is_Invalid}
                                                    filterOn = "Risk Rating"
                                                    options = {riskClassifications}
                                                    selectedOptions = {grcFormData?.Risk_Classification || null}
                                                    handleSelectChange = {(filterOn, newValue) => handleGRCChange(index, "Risk_Classification", newValue)}
                                                />
                                            </Grid>

                                            <Grid size = {{ xs: 4 }}>
                                                <VirtualizedAutocomplete
                                                    isMultiple = {false}
                                                    isObject = {false}
                                                    isWritable = {isWritable}
                                                    isRequired = {grcFormData?.Is_Required}
                                                    isDisabled = {grcFormData?.Is_Invalid}
                                                    filterOn = "Counter Party Status"
                                                    options = {["Future Transaction Blocked", "Proceed with Caution", "On-Boarded and Available to Use"]}
                                                    selectedOptions = {grcFormData?.KYC_Status || null}
                                                    handleSelectChange = {(filterOn, newValue) => handleGRCChange(index, "KYC_Status", newValue)}
                                                />
                                            </Grid>

                                            <Grid size = {{ xs: 4 }}>
                                                <VirtualizedAutocomplete
                                                    isMultiple = {false}
                                                    isObject = {false}
                                                    isWritable = {isWritable}
                                                    isRequired = {grcFormData?.Is_Required}
                                                    isDisabled = {grcFormData?.Is_Invalid}
                                                    filterOn = "Execution"
                                                    options = {["Approved", "Rejected", "Reverted Back"]}
                                                    selectedOptions = {grcFormData?.KYC_Execution || null}
                                                    handleSelectChange = {(filterOn, newValue) => handleGRCChange(index, "KYC_Execution", newValue)}
                                                />
                                            </Grid>

                                            <Grid size = {{ xs: 4 }} className = "form-text-field">
                                                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                                                    <DesktopDatePicker
                                                        inputFormat = "MM/DD/YYYY"
                                                        label = "Next Review"
                                                        disableMaskedInput
                                                        className = "date-picker w-full"
                                                        value = {convertToDayJsObject(grcFormData?.Next_Review)}
                                                        onChange = {(newDate) => handleGRCChange(index, "Next_Review", newDate, true)}
                                                        onAccept = {(newDate) => handleGRCChange(index, "Next_Review", newDate, true)}
                                                        minDate = {dayjs()}
                                                        readOnly = {!isWritable}
                                                        disabled = {grcFormData?.Is_Invalid}
                                                        slotProps = {{
                                                            actionBar: {
                                                                actions: ["clear"]
                                                            },
                                                            textField: {
                                                                InputProps: {
                                                                    size: "small",
                                                                    required: grcFormData?.Is_Required,
                                                                    disabled: true
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </LocalizationProvider>
                                            </Grid>

                                            <Grid size = {{ xs: 12 }} className = "form-text-field">
                                                <TextField
                                                    label = "Comments"
                                                    id = "Comments"
                                                    multiline
                                                    rows = {3}
                                                    disabled = {grcFormData?.Is_Invalid}
                                                    value = {grcFormData.Comments || ""}
                                                    onChange = {(event) => handleGRCChange(index, "Comments", event.target.value)}
                                                    slotProps = {{
                                                        input: {
                                                            readOnly: !isWritable
                                                        }
                                                    }}
                                                />
                                            </Grid>

                                            <Grid size = {{ xs: 12 }}>
                                              <FormControlLabel 
                                                label = "Mark as Invalid"
                                                control = {
                                                    <Checkbox 
                                                        size = "small"
                                                        checked = {grcFormData?.Is_Invalid || false}
                                                        onChange = {(event) => handleGRCChange(index, "Is_Invalid", event.target.checked)}
                                                        sx = {{
                                                            paddingTop: '6px',
                                                            paddingBottom: '6px',
                                                            paddingRight: '5px'
                                                        }}
                                                    />
                                                } 
                                              />
                                            </Grid>
                                        </Grid>
                                    </div>
                                ))}

                                <Grid size = {{ xs: 12 }} className = "form-text-field">
                                    <FileUploader
                                        isWritable = {isDocumentWritable && !(formData?.GRC_Specific_Details?.every(({ Is_Invalid }) => Is_Invalid))}
                                        isLoading = {isLoading}
                                        isMultiple = {true}
                                        isKYC = {true}
                                        pathname = {location?.pathname}
                                        uploadText = "Please upload GRC related files here."
                                        files = {grcKYCFiles}
                                        uploadedFiles = {uploadedKYCFiles}
                                        allowFileTypeSelection = {true}
                                        kycFileTypes = {kycFileTypes}
                                        setIsLoading = {setIsLoading}
                                        setShowAlert = {setShowAlert}
                                        setAlertMessage = {setAlertMessage}
                                        setAlertType = {setAlertType}
                                        setFiles = {setGRCKYCFiles}
                                        setUploadedFiles = {setUploadedKYCFiles}
                                        logOut = {logOut}
                                    />
                                </Grid>
                            </>
                        )}
                    </>
                )}

                <button ref = {submitButtonRef} type = "submit" className = "hidden"></button>
            </form>
            <br />
            <br />

            <div className = "d-flex gap-2 justify-content-end submit-buttons">
                <button
                    type = "button"
                    variant = "contained"
                    color = "primary"
                    disabled = {!isWritable || isLoading || !isEditable || isTimeLeft || (isGRCRole && formData?.GRC_Specific_Details?.every(
                        ({ Is_Required, KYC_Execution }) => !Is_Required && KYC_Execution !== "Reverted Back"
                    ))}
                    className = "btn btn_secondary custom_btn"
                    onClick = {() => submitButtonRef?.current?.click()}
                >
                    Submit
                </button>
            </div>

            {showAddCounterPartyModal && (
                <Form
                    show = {showAddCounterPartyModal}
                    title = "Counter Party"
                    pathname = {location?.pathname}
                    columns = {counterPartyFields}
                    selectedRow = {{}}
                    dropdowns = {dropdowns}
                    rowType = "Add"
                    setIsLoading = {setIsLoading}
                    setShowAlert = {setShowAlert}
                    setAlertMessage = {setAlertMessage}
                    setAlertType = {setAlertType}
                    handleSubmitAddedRow = {handleAddCounterParty}
                    handleClose = {handleCloseModal}
                    logOut = {logOut}
                />
            )}
        </div>
    );
}

export default withAuth(EditKYC)([
    roles[4], 
    roles[5], 
    roles[6], 
    roles[7], 
    roles[10], 
    roles[11], 
    roles[12], 
    roles[13], 
    roles[14],
    roles[15], 
    roles[16], 
    roles[17],
    roles[18]
]);