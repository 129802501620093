import { forwardRef } from 'react';
import TextField from '@mui/material/TextField';

const CustomPhoneInput = (props, ref) => 
{
    return (
        <TextField
            {...props}
            inputRef = {ref}
            fullWidth
            size = "small"
            label = "Contact Info (Mobile No.)"
            variant = "outlined"
            name = "phone"
        />
    );
}

export default forwardRef(CustomPhoneInput);