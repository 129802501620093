import { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getRoutesByRole } from '../utility/routes';
import useUser from '../hooks/useUser';
import useSessionExpire from '../hooks/useSessionExpire';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '../components/Alert';
import axios from 'axios';

const BusinessUnitContext = createContext();

const BusinessUnitProvider = ({ children, logOut = () => {} }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const { pathname } = useLocation();

    /* AUTHENTICATION VARIABLES */
    const currentUser = useUser();

    /* SESSION EXPIRY VARIABLES */
    const triggerSessionExpire = useSessionExpire();

    /* LIST VARIABLES */
    const [businessUnits, setBusinessUnits] = useState([]);

    useEffect(() => 
    {
        const fetchBusinessUnits = async () => 
        {
            if (businessUnits.length === 0)
            {
                setIsLoading(true);
            }
            
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");

            await axios({
                method: "post",
                url: "/GetBusinessUnitList",
                data: { pathname }
            })
            .then((response) => 
            {
                setIsLoading(false);
                const { status, data } = response;

                if (status === 200) 
                {
                    const businessUnits = data?.business_units || [];
                    setBusinessUnits(businessUnits);
                }
                else 
                {
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get Business Units Api: ", error);
                setIsLoading(false);
                
                const status = error?.response?.status;

                if (status === 403) 
                {
                    triggerSessionExpire();
                }
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }
    
        const validCategories = new Set(["deals", "entities", "reports"]);
        const validSubCategories = new Set(["entities/products", "entities/counter-parties"]);
        const validRoutesByRole = new Set(getRoutesByRole(currentUser?.role));        
        const category = pathname.split('/')[1];
        const isValid = validRoutesByRole.has(pathname) && (
            validCategories.has(category) && 
            (category !== "entities" || validSubCategories.has(pathname))
        );
        
        if (currentUser?.id && isValid)
        {
            fetchBusinessUnits();
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [currentUser]);
    
    return (
        <BusinessUnitContext.Provider value = {businessUnits}>
            <Alert
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />

            <Backdrop
                sx = {{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open = {isLoading}
            >
                <CircularProgress color = "inherit" />
            </Backdrop>

            {children}
        </BusinessUnitContext.Provider>
    );
}

export { BusinessUnitContext, BusinessUnitProvider };