import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { roles } from '../../utility/roles';
import { getPermissions } from '../../lib/accessControl';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import MUIDataTable from '../../components/MUIDataTable';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from 'react-bootstrap/Modal';
import Alert from '../../components/Alert';
import withAuth from '../../lib/withAuth';
import axios from 'axios';

const Bank = ({ logOut = () => {} }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const { pathname } = useLocation();

  /* AUTHORIZATION VARIABLES */
  const currentUser = useUser();
  const { isWritable = false } = getPermissions(currentUser?.role, pathname);

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();

  /* LIST VARIABLES */
  const [dropdowns, setDropdowns] = useState({});

  /* EDITABLE ROW VARIABLES */
  const [isEditableRecordLoading, setIsEditableRecordLoading] = useState(false);
  const [recordToEdit, setRecordToEdit] = useState(null);

  /* DATATABLE VARIABLES */
  const [columnData, setColumnData] = useState([]);
  const [columnDataTypes, setColumnDataTypes] = useState({});
  const [columnWidths, setColumnWidths] = useState({});
  const [rows, setRows] = useState([]);
  const [toggleDisplayData, setToggleDisplayData] = useState(false);

  /* DATATABLE ROW VARIABLES */
  const [rowId, setRowId] = useState(null);
  const [rowStatus, setRowStatus] = useState(null);

  /* MODAL VARIABLES */
  const [showDisableModal, setShowDisableModal] = useState(false);

  useEffect(() =>
  {
    const fetchLists = async () => 
    {
      axios({
        method: "post",
        url: "/GetCurrencyList",
        data: { pathname }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200)
        {
          const currencies = data?.currencies || [];

          setDropdowns((previousDropdowns) => (
          {
            ...previousDropdowns,
            Currency: currencies
          }));
        }
      })
      .catch((error) => 
      { 
        console.log("Get Currencies Api: ", error);
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    fetchLists();
  
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
      setRowId(null);
      setRowStatus(null);

      await axios({
        method: "get",
        url: "/GetBanks"
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const bankData = data?.bank_data || {};
          const { rows: rowData = [], columns = [], data_types: dataTypes = {}, max_column_lengths: widths = {} } = bankData;
          const columnData = columns.filter(column => !(column === "id" || column === "isDeleted")) || [];

          setColumnData(columnData);
          setColumnDataTypes(dataTypes);
          setColumnWidths(widths);
          setRows(rowData);
        }
        else
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {  
        console.log("Get Bank Data Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    fetchData();
  
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [toggleDisplayData]);

  const handleUpdateRecord = async (editedRecord) => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/UpdateBank",
      data: editedRecord
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setToggleDisplayData(!toggleDisplayData);
      }
      else
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    { 
      console.log("Update Bank Api: ", error);
      setIsLoading(false);
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleAddRecord = async (addedRecord) => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/AddBank",
      data: addedRecord
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;
        
      if (status === 200) 
      {
        setToggleDisplayData(!toggleDisplayData);
      }
      else
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Add Bank Api: ", error);
      setIsLoading(false);
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleRecordStatusChangeWrapper = (id, status) =>
  {
    setRowId(id);
    setRowStatus(status);

    if (status === 1)
    {
      setShowDisableModal(true);
    }
    else
    {
      handleRecordStatusChange(id, status);
    }
  }
  
  const handleRecordStatusChange = useCallback(async (id, status) => 
  {
    setShowDisableModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/SetBankStatus",
      data: { 
        id: rowId || id, 
        status: rowStatus || status
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setToggleDisplayData(!toggleDisplayData);
      }
      else
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    { 
      console.log("Set Bank Status Api: ", error);
      setIsLoading(false);
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rowId, rowStatus]);

  const handleGetRecord = async (rowId) =>
  {
    setRecordToEdit(null);
    setIsEditableRecordLoading(true);
    
    await axios({
      method: "post",
      url: "/GetBankRecord",
      data: {
        bank_id: rowId
      }
    })
    .then((response) => 
    {
      setIsEditableRecordLoading(false);
      const { status, data } = response;
    
      if (status === 200) 
      {
        setRecordToEdit(data);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Get Bank Record Api: ", error);
      setIsEditableRecordLoading(false);
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleCloseModal = () =>
  {
    setShowDisableModal(false);
  }

  return (
    <div className = "content-container d-flex flex-column container entities">
      <Alert 
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />

      <Typography variant = "h5" gutterBottom className = "page-heading m-0">
        Banks
      </Typography>
      
      <div>
        <Backdrop
          sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open = {isLoading}
        />
        
        <MUIDataTable
          title = "Bank"
          isWritable = {isWritable}
          isLoading = {isLoading}
          pathname = {pathname}
          columnData = {columnData}
          columnDataTypes = {columnDataTypes}
          columnWidths = {columnWidths}
          rows = {rows}
          doesContainDropdowns = {true}
          dropdowns = {dropdowns}
          files = {[]}
          isEditable = {true}
          isEditableRecordLoading = {isEditableRecordLoading}
          recordToEdit = {recordToEdit}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          handleUpdateRecord = {handleUpdateRecord}
          handleAddRecord = {handleAddRecord}
          handleRecordStatusChange = {handleRecordStatusChangeWrapper}
          handleGetRecord = {handleGetRecord}
          logOut = {logOut}
        />
      </div>

      <Modal show = {showDisableModal} onHide = {handleCloseModal} centered = {true}>
        <Modal.Header closeButton = {true}>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Disabling the bank will remove it from the list of available banks.</span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleRecordStatusChange}>
            Disable
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default withAuth(Bank)([
  roles[4], 
  roles[5], 
  roles[9], 
  roles[10], 
  roles[11], 
  roles[13], 
  roles[14],
  roles[16], 
  roles[17], 
  roles[19]
]);