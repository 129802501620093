import { useEffect, useState, memo } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Form from './Form';

const StyledDataGrid = styled(DataGrid)(({ theme }) => (
{

    '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d'
    },
    '& .MuiDataGrid-iconSeparator': {
        display: 'none'
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`
    },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`
    },
    '& .MuiDataGrid-cell': {
        color: theme.palette.mode === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.65)'
    },
    '& .MuiPaginationItem-root': {
        borderRadius: 0
    },
    '& .MuiDataGrid-cell:hover': {
        color: 'primary.main'
    }
}));

const StyledGridOverlay = styled('div')(({ theme }) =>
({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
    }
}));

export const CustomNoRowsOverlay = () => 
{
    return (
        <StyledGridOverlay>
            <svg
                width = "120"
                height = "100"
                viewBox = "0 0 184 152"
                focusable = "false"
                aria-hidden
            >
                <g fill = "none" fillRule = "evenodd">
                    <g transform = "translate(24 31.67)">
                        <ellipse
                            className = "ant-empty-img-5"
                            cx = "67.797"
                            cy = "106.89"
                            rx = "67.797"
                            ry = "12.668"
                        />

                        <path
                            className = "ant-empty-img-1"
                            d = "M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />

                        <path
                            className = "ant-empty-img-2"
                            d = "M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />

                        <path
                            className = "ant-empty-img-3"
                            d = "M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>

                    <path
                        className = "ant-empty-img-3"
                        d = "M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />

                    <g className = "ant-empty-img-4" transform = "translate(149.65 15.383)">
                        <ellipse cx = "20.654" cy = "3.167" rx = "2.849" ry = "2.815" />
                        <path d = "M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                    </g>
                </g>
            </svg>

            <Box sx = {{ mt: 1 }}>No Data</Box>
        </StyledGridOverlay>
    );
}

const MUIDataTable = ({ title = "", isWritable = false, isLoading = false, pathname = null, columnData = [], columnDataTypes = {}, columnWidths = {}, rows = [], doesContainDropdowns = false, dropdowns = {}, allowFileUpload = false, files = [], uploadedFiles = [], isEditable = false, isEditableRecordLoading = false, recordToEdit = null, setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, setFiles = () => {}, setUploadedFiles = () => {}, setRecordToEdit = () => {}, handleUpdateRecord = () => {}, handleEditSBLC = () => {}, handleAddRecord = () => {}, handleAddSBLC = () => {}, handleRecordStatusChange = () => {}, handleCellClick = () => {}, handleGetRecord = () => {}, logOut = () => {} }) => 
{
    /* DATATABLE VARIABLES */
    const [columns, setColumns] = useState([]);
    const [tableWidth, setTableWidth] = useState("100%");

    /* ROW VARIABLES */
    const [selectedRow, setSelectedRow] = useState({});
    const [rowType, setRowType] = useState(null);
    const [rowFiles, setRowFiles] = useState([]);
    const [deletedRowFileIds, setDeletedRowFileIds] = useState([]);

    /* MODAL VARIABLES */
    const [showModal, setShowModal] = useState(false);
    
    useEffect(() => 
    {
        if (columnData?.length > 0) 
        {
            let tempColumns = [];
            let columnWidthSum = 120;

            for (let i = 0; i < columnData.length; i++) 
            {
                const width = (parseInt(columnWidths[columnData[i]]) || 0) * 0.5 * 13 + 40;
                columnWidthSum += width;

                if (columnDataTypes[columnData[i]].includes("object") || columnDataTypes[columnData[i]].includes("date")) 
                {
                    tempColumns.push(
                    {
                        field: columnData[i],
                        headerName: columnData[i].replaceAll("_", " "),
                        headerClassName: 'header',
                        headerAlign: 'center',
                        type: 'string',
                        minWidth: 100,
                        width: width,
                        cellClassName: (params) => 
                        {
                            let classname = "";

                            if (title === "SBLC" && params?.field === "SBLC Number" && !(params.row?.isDeleted || params.row?.is_deleted)) 
                            {
                                classname += " view-sblc-details";
                            }

                            return classname;
                        }
                    });
                }
                else if (columnDataTypes[columnData[i]] !== "object") 
                {
                    tempColumns.push(
                    {
                        field: columnData[i],
                        headerName: columnData[i].replaceAll("_", " "),
                        headerClassName: 'header',
                        headerAlign: 'center',
                        type: 'number',
                        minWidth: 80,
                        width: width,
                        valueGetter: (value) => {
                            if (title === "File Type" && columnData[i] === "Mark_for_Approval")
                            {
                                return value ? "Yes" : "No";
                            }

                            return value;
                        },
                        cellClassName: (params) => {
                            if (title === "File Type" && columnData[i] === "Mark_for_Approval")
                            {
                                return "justify-content-start";
                            }
                        }
                    });
                }
            }

            tempColumns.push(
            {
                field: 'actions',
                headerName: 'Action(s)',
                headerAlign: 'center',
                headerClassName: 'custom_actions_header',
                width: 120,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => (
                    <div className = "d-flex justify-content-center align-items-center w-100 gap-2 h-full mx-2">
                        {(isEditable && (!params.row?.isDeleted && !params.row?.is_deleted)) &&
                            <IconButton
                                title = "Edit"
                                className = "custom_edit_icon"
                                disabled = {!isWritable}
                                onClick = {() => handleEditRow(params.row)}
                            >
                                <EditIcon />
                            </IconButton>
                        }

                        <Button
                            className = "capitalize"
                            color = {params.row?.isDeleted || params.row?.is_deleted ? "success" : "error"}
                            disabled = {!isWritable}
                            onClick = {() => handleRowStatusChange(params.row)}
                        >
                            <strong>
                                {params.row?.isDeleted || params.row?.is_deleted ? "Enable" : "Disable"}
                            </strong>
                        </Button>
                    </div>
                )
            });

            setColumns(tempColumns);
            setTableWidth(columnWidthSum);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [columnData]);

    useEffect(() => 
    {
        if (deletedRowFileIds.length > 0) 
        {
            const updatedFiles = files.filter(file => !deletedRowFileIds.includes(file.file.id)) || [];
            setFiles(updatedFiles);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [deletedRowFileIds, files]);

    useEffect(() => 
    {
        if (recordToEdit) 
        {
            setSelectedRow(recordToEdit);
            setRowType("Existing");
        }
    }, [recordToEdit]);

    const handleEditRow = (row) => 
    {
        if (title === "SBLC") 
        {
            handleEditSBLC(row);
        } 
        else if (doesContainDropdowns) 
        {
            handleGetRecord(row?.id);

            const rowFiles = files?.filter(file => parseInt(file.file.DealPFIId) === row.id) || [];
            setRowFiles(rowFiles);
            setShowModal(true);
        }
        else 
        {
            const rowFiles = files?.filter(file => parseInt(file.file.DealPFIId) === row.id);

            setSelectedRow(row);
            setRowFiles(rowFiles);
            setRowType("Existing");
            setShowModal(true);
        }
    }

    const handleAddRow = () => 
    {
        if (title === "SBLC") 
        {
            handleAddSBLC();
        }
        else 
        {
            setRowFiles([]);
            setRowType("New");
            setShowModal(true);
        }
    }

    const handleRowStatusChange = (row) => 
    {
        const rowStatus = row.isDeleted || row.is_deleted ? 0 : 1;
        handleRecordStatusChange(row.id, rowStatus);
    }

    const handleSubmitEditedRow = (row) => 
    {
        handleUpdateRecord(row);
        handleCloseModal();
    }

    const handleSubmitAddedRow = (row) => 
    {
        handleAddRecord(row);
        handleCloseModal();
    }

    const handleCloseModal = () => 
    {
        setRecordToEdit(null)
        setShowModal(false);
        setSelectedRow({});
        setRowFiles([]);
        setUploadedFiles([]);
    }

    return (
        <div
            className = {`datagrid m-auto ${isLoading ? "loading" : (rows?.length === 0) ? "no-data loading" : ""}`}
            style = {{ width: tableWidth }}
        >
            {isEditable && (
                <div className = "add-button">
                    <Button 
                        variant = "contained" 
                        size = "small" 
                        disabled = {!isWritable} 
                        onClick = {handleAddRow}
                    >
                        Add Record
                    </Button>
                </div>
            )}

            <StyledDataGrid
                loading = {isLoading}
                density = "compact"
                columns = {columns}
                rows = {rows}
                rowHeight = {21}
                editMode = "row"
                getRowId = {row => row.id}
                autoPageSize = {true}
                rowsPerPageOptions = {[]}
                pagination = {true}
                disableExtendRowFullWidth = {true}
                disableColumnSelector = {true}
                hideFooterSelectedRowCount = {true}
                columnBuffer = {columns?.length - 1}
                columnThreshold = {50}
                onCellClick = {(params) => (title === "SBLC" && params?.field === "SBLC Number" && !(params.row?.isDeleted || params.row?.is_deleted)) ? handleCellClick(params?.row) : {}}
                components = {{ LoadingOverlay: LinearProgress, NoRowsOverlay: CustomNoRowsOverlay }}
            />

            {showModal && (
                <Form
                    show = {showModal}
                    title = {title}
                    isLoading = {isEditableRecordLoading}
                    pathname = {pathname}
                    columns = {columns}
                    selectedRow = {selectedRow}
                    dropdowns = {dropdowns}
                    rowType = {rowType}
                    allowFileUpload = {allowFileUpload}
                    rowFiles = {rowFiles}
                    uploadedFiles = {uploadedFiles}
                    setIsLoading = {setIsLoading}
                    setShowAlert = {setShowAlert}
                    setAlertMessage = {setAlertMessage}
                    setAlertType = {setAlertType}
                    setRowFiles = {setRowFiles}
                    setUploadedFiles = {setUploadedFiles}
                    setDeletedRowFileIds = {setDeletedRowFileIds}
                    handleSubmitEditedRow = {handleSubmitEditedRow}
                    handleSubmitAddedRow = {handleSubmitAddedRow}
                    handleClose = {handleCloseModal}
                    logOut = {logOut}
                />
            )}
        </div>
    );
}

export default memo(MUIDataTable);