import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getPermissions } from '../../lib/accessControl';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import FileUploader from '../../components/FileUploader';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ReportDownloader from '../../components/ReportDownloader';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddIcon from '@mui/icons-material/Add';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import axios from 'axios';
import dayjs from 'dayjs';

const Documentation = forwardRef(({ isWritable = false, isLoading = false, selectedPFI = null, setIsUpdated = () => {}, setActiveTab = () => {}, setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, logOut = () => {} }, ref) => 
{
  const location = useLocation();
  const { selectedBusinessUnit } = location.state || {};
  const { isFoodsOrPolymersDeal, isSBLCDeal } = useMemo(() => 
  {
    const id = selectedBusinessUnit?.id;
    
    return {
      isFoodsOrPolymersDeal: [1, 4].includes(id),
      isSBLCDeal: id === 6,
    };

  }, [selectedBusinessUnit]);

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* AUTHORIZATION VARIABLES */
  const { isWritable: isDocumentWritable = false } = getPermissions(currentUser?.role, null, "documents");
  const documentPermissions = getPermissions(currentUser?.role, null, "approvableDocuments");

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();
  
  /* FILTER VARIABLES */
  const [tranches, setTranches] = useState([]);
  const [selectedTranche, setSelectedTranche] = useState(null);
  const [toggleFilterData, setToggleFilterData] = useState(false);

  /* COPY VARIABLES */
  const [tranchesToCopy, setTranchesToCopy] = useState([]);
  const [selectedTrancheToCopy, setSelectedTrancheToCopy] = useState(null);

  /* LIST VARIABLES */
  const [coos, setCOOs] = useState([]);
  const [documentRevisionNumbers, setDocumentRevisionNumbers] = useState([]);
  const [buyingLegFileTypes, setBuyingLegFileTypes] = useState([]);
  const [sellingLegFileTypes, setSellingLegFileTypes] = useState([]);
  const receivedPayments = useMemo(() => ["Yes", "No"], []);
  const discountingOptions = useMemo(() => ["Yes", "No"], []);
  const discrepancyAcceptanceSwifts = useMemo(() => ["Yes", "No"], []);
  const transactionTyoes = useMemo(() => ["Addition", "Subtraction"], []);
  const buyerDocumentStatus = useMemo(() => ["Received - Clean", "Received - Discrepant"], []);
  const sellerDocumentStatus = useMemo(() => ["Dispatched - Clean", "Dispatched - Discrepant"], []);

  /* SELECTED LIST VARIABLES */
  const [selectedCOO, setSelectedCOO] = useState(null);
  const [selectedDocumentRevisionNumber, setSelectedDocumentRevisionNumber] = useState(null);

  /* FILE VARIABLES */
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [toggleFileData, setToggleFileData] = useState(false);

  /* FORM VARIABLES */
  const dataSchema = {
    AWB_from_Supplier: "",
    Buying_Payment_Term: null,
    Buying_Tenor: null,
    Buying_Tenor_Days: 0,
    Advance_Payment_Date: null,
    Remaining_Balance_Payment_Date: null,
    Supplier_Document_Receiving_Date: null,
    Disc_Acceptance: "",
    Received_Payment: "",
    AWB_To_Customer: "",
    Selling_Payment_Term: null,
    Selling_Tenor: null,
    Selling_Tenor_Days: 0,
    Advance_Receiving_Date: null,
    Remaining_Balance_Receiving_Date: null,
    Dispatch_Date: null,
    Discounting_Required: null,
    Customer_Transaction_Status: null,
    Supplier_Transaction_Status: null,
    Advance_Amount: null,
    Discount: null,
    Total_Amount: null,
    Freight: null,
    FOB_Value: null,
    Label: "",
    Value: null,
    Transaction_Type: null,
    Supplier_Status: "",
    Supplier_Discrepancy_Reason: "",
    Supplier_Discrepancy_Acceptance_Swift: "",
    Customer_Status: "",
    Customer_Discrepancy_Reason: "",
    Customer_Discrepancy_Acceptance_Swift: "",
    Special_Requirement: "",
    Special_Requirement_SI: "",
    Special_Requirement_CI: "",
    Special_Requirement_SA: "",
    Special_Requirement_PL: "",
    Special_Requirement_BE: "",
    Target_Customer_PFI_Issuance_Date: null,
    Target_Supplier_LC_Issuance_Date: null,
    Target_Customer_NN_Documents: null,
    Target_Supplier_NN_Documents: null,
    Target_Supplier_Original_Documents: null,
    Target_Customer_Original_Documents: null,
    Customer_LC_Deadline: null,
    Shipment_Date: null
  };
  const [initialFormData, setInitialFormData] = useState(dataSchema);
  const [formData, setFormData] = useState(dataSchema);
  const [isExistingDocumentation, setIsExistingDocumentation] = useState(false);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [isFormSaved, setIsFormSaved] = useState(false);
  const [isNewForm, setIsNewForm] = useState(false);
  const [isFormSavedOrDeleted, setIsFormSavedOrDeleted] = useState(false);
  const formRef = useRef(null);
  const submitButtonRef = useRef(null);

  /* DOCUMENT VARIABLES */
  const [areDocumentRevisionNumbersLoading, setAreDocumentRevisionNumbersLoading] = useState(false);
  const [areCOOsLoading, setAreCOOsLoading] = useState(false);
  const [documentGenerationData, setDocumentGenerationData] = useState({});
  const [draftDocuments, setDraftDocuments] = useState([]);
  const [approvedDocuments, setApprovedDocuments] = useState([]);
  const [documentType, setDocumentType] = useState(null);
  const [addTermsAndConditionsPage, setAddTermsAndConditionsPage] = useState(false);
  const [toggleDocumentList, setToggleDocumentList] = useState(false);
  
  /* DOCUMENT BUTTON VARIABLES */
  const pfiDocumentDownloadButtonRef = useRef(null);
  const siDocumentDownloadButtonRef = useRef(null);
  const ciDocumentDownloadButtonRef = useRef(null);
  const saDocumentDownloadButtonRef = useRef(null);
  const plDocumentDownloadButtonRef = useRef(null);
  const beDocumentDownloadButtonRef = useRef(null);
  // const poDocumentDownloadButtonRef = useRef(null);
  const cooDocumentDownloadButtonRef = useRef(null);
  const [selectedDocumentGenerationButtonRef, setSelectedDocumentGenerationButtonRef] = useState(null);

  /* INPUT REFERENCE VARIABLES */
  const freightInputRef = useRef(null);
  const fobValueInputRef = useRef(null);

  /* TAB VARIABLES */
  const [selectedTab, setSelectedTab] = useState(0);
  
  /* DIALOG VARIABLES */
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  
  /* MODAL VARIABLES */
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);

  useEffect(() => 
  {
    const fetchCOOList = async () => 
    {
      setAreCOOsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await axios({
        method: "post",
        url: "/GetCertificatesOfOrigin",
        data: {
          Business_Unit_Id: selectedBusinessUnit?.id,
          Tranche_Id: selectedTrancheToCopy?.id || selectedTranche?.id
        }
      })
      .then((response) => 
      {
        setAreCOOsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          setCOOs(data);
        }
      })
      .catch((error) => 
      {
        console.log("Get COO List Api: ", error);
        setAreCOOsLoading(false);

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    if (selectedTranche?.id) 
    {
      fetchCOOList();
      // Assuming you have a list of all tranches, filter out the selected one
      const filteredTranches = tranches.filter(tranche => tranche.id !== selectedTranche?.id);
      setTranchesToCopy(filteredTranches);
    }
  
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedTranche, toggleDocumentList]);
   
  useEffect(() => 
  {
    const fetchDocumentList = async () => 
    {
      setAreDocumentRevisionNumbersLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await axios({
        method: "post",
        url: "/GetDocumentRevisions",
        data: { 
          Business_Unit_Id: selectedBusinessUnit?.id,
          Deal_PFI_Id: selectedPFI,
          Tranche_Id: selectedTranche?.id
        }
      })
      .then((response) => 
      {
        setAreDocumentRevisionNumbersLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const { document_revision_data: documentRevisionData = [], document_generation_data: documentGenerationData = {} } = data || {};

          setDocumentRevisionNumbers(documentRevisionData);
          setDocumentGenerationData(documentGenerationData);
        }
      })
      .catch((error) => 
      {
        console.log("Get Document Revision Number List Api: ", error);
        setAreDocumentRevisionNumbersLoading(false);

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    if (selectedPFI) 
    {
      fetchDocumentList();
    }
  
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, selectedTranche, toggleDocumentList]);
  
  useEffect(() => 
  {
    if (!isFormSavedOrDeleted && selectedTranche)
    {
      // Check if form data is different from the initial values
      const isFormUpdated = Object.keys(initialFormData).some((key) => 
      {
        const formDataValue = formData[key];
        const initialFormDataValue = initialFormData[key];

        return (JSON.stringify(formDataValue) !== JSON.stringify(initialFormDataValue));
      });

      setIsUpdated(isFormUpdated);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [formData]);

  useEffect(() => 
  {
    const fetchFilters = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      axios({
        method: "post",
        url: "/GetExternalFileTypeList",
        data: { 
          Business_Unit_Id: selectedBusinessUnit?.id
        }
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const { 
            buying_leg_file_types: buyingLegFileTypes = [], 
            selling_leg_file_types: sellingLegFileTypes = [] 
          } = data || {};

          setBuyingLegFileTypes(buyingLegFileTypes);  
          setSellingLegFileTypes(sellingLegFileTypes);
        }
      })
      .catch((error) => 
      {
        console.log("Get External File Type List Api: ", error);
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
  
      await axios({
        method: "post",
        url: "/GetDocumentationDetailFilters",
        data: { 
          Business_Unit_Id: selectedBusinessUnit?.id,
          Deal_PFI_Id: selectedPFI 
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;
  
        if (status === 200) 
        {
          if (isNewForm) 
          {
            setIsNewForm(false);
          }
  
          const tranches = data?.tranches || [];
  
          if (tranches.length > 0) 
          {
            if (formData?.Tranche_Name)
            {
              const latestTranche = tranches.find(tranche => tranche.value === formData?.Tranche_Name);
              setSelectedTranche(latestTranche);
            }
            else
            {
              setSelectedTranche(tranches[0]);
            }
          }
  
          setTranches(tranches);
        } 
        else if (status === 400)
        {
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Documentation Detail Filters Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }
    
    if (selectedPFI) 
    {
      fetchFilters();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, toggleFilterData]);

  useEffect(() => 
  {
    const fetchDocumentationData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
      
      await axios({
        method: "post",
        url: "/GetDocumentation",
        data: {
          Business_Unit_Id: selectedBusinessUnit?.id,
          Deal_PFI_Id: selectedPFI,
          Tranche_Id: selectedTrancheToCopy?.id || selectedTranche?.id || ""
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200)
        {
          const isEmpty = typeof data === "object" ? selectedTranche ? false : true : true;
          
          if (!isEmpty) 
          {
            setIsExistingDocumentation(true);
          } 
          else 
          {
            setIsNewForm(true);
          }

          let updatedData = { ...data };

          updatedData["Advance_Payment_Date"] = updatedData["Advance_Payment_Date"] ? dayjs(updatedData["Advance_Payment_Date"]).format("MM/DD/YYYY") : updatedData["Advance_Payment_Date"];
          updatedData["Remaining_Balance_Payment_Date"] = updatedData["Remaining_Balance_Payment_Date"] ? dayjs(updatedData["Remaining_Balance_Payment_Date"]).format("MM/DD/YYYY") : updatedData["Remaining_Balance_Payment_Date"];
          updatedData["Advance_Receiving_Date"] = updatedData["Advance_Receiving_Date"] ? dayjs(updatedData["Advance_Receiving_Date"]).format("MM/DD/YYYY") : updatedData["Advance_Receiving_Date"];
          updatedData["Remaining_Balance_Receiving_Date"] = updatedData["Remaining_Balance_Receiving_Date"] ? dayjs(updatedData["Remaining_Balance_Receiving_Date"]).format("MM/DD/YYYY") : updatedData["Remaining_Balance_Receiving_Date"];
          updatedData["Supplier_Document_Receiving_Date"] = updatedData["Supplier_Document_Receiving_Date"] ? dayjs(updatedData["Supplier_Document_Receiving_Date"]).format("MM/DD/YYYY") : updatedData["Supplier_Document_Receiving_Date"];
          updatedData["Dispatch_Date"] = updatedData["Dispatch_Date"] ? dayjs(updatedData["Dispatch_Date"]).format("MM/DD/YYYY") : updatedData["Dispatch_Date"];
          updatedData["Target_Customer_PFI_Issuance_Date"] = updatedData["Target_Customer_PFI_Issuance_Date"] ? dayjs(updatedData["Target_Customer_PFI_Issuance_Date"]).format("MM/DD/YYYY") : updatedData["Target_Customer_PFI_Issuance_Date"];
          updatedData["Target_Supplier_LC_Issuance_Date"] = updatedData["Target_Supplier_LC_Issuance_Date"] ? dayjs(updatedData["Target_Supplier_LC_Issuance_Date"]).format("MM/DD/YYYY") : updatedData["Target_Supplier_LC_Issuance_Date"];
          updatedData["Target_Customer_NN_Documents"] = updatedData["Target_Customer_NN_Documents"] ? dayjs(updatedData["Target_Customer_NN_Documents"]).format("MM/DD/YYYY") : updatedData["Target_Customer_NN_Documents"];
          updatedData["Target_Supplier_NN_Documents"] = updatedData["Target_Supplier_NN_Documents"] ? dayjs(updatedData["Target_Supplier_NN_Documents"]).format("MM/DD/YYYY") : updatedData["Target_Supplier_NN_Documents"];
          updatedData["Target_Supplier_Original_Documents"] = updatedData["Target_Supplier_Original_Documents"] ? dayjs(updatedData["Target_Supplier_Original_Documents"]).format("MM/DD/YYYY") : updatedData["Target_Supplier_Original_Documents"];
          updatedData["Target_Customer_Original_Documents"] = updatedData["Target_Customer_Original_Documents"] ? dayjs(updatedData["Target_Customer_Original_Documents"]).format("MM/DD/YYYY") : updatedData["Target_Customer_Original_Documents"];
          updatedData["Customer_LC_Deadline"] = updatedData["Customer_LC_Deadline"] ? dayjs(updatedData["Customer_LC_Deadline"]).format("MM/DD/YYYY") : updatedData["Customer_LC_Deadline"];
          
          // Set 'Freight' and 'FOB_Value' based on 'Total_Amount'
          const shouldResetValues = ((updatedData.Freight || 0) + (updatedData.FOB_Value || 0)) !== (updatedData.Total_Amount || 0);

          updatedData.Freight = shouldResetValues ? null : updatedData.Freight;
          updatedData.FOB_Value = shouldResetValues ? null : updatedData.FOB_Value;          

          if (selectedTranche) 
          {
            updatedData["Tranche_Name"] = selectedTranche?.value;
            updatedData["Tranche_Id"] = selectedTranche?.id;
          }

          setInitialFormData(JSON.parse(JSON.stringify(updatedData)));
          setFormData(updatedData);
        } 
        else if (status === 400)
        {
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Documentation Data Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    const filteredTranches = tranches.filter(tranche => tranche.id !== selectedTranche?.id);
    setTranchesToCopy(filteredTranches);

    if (selectedPFI && (((selectedTrancheToCopy || selectedTranche) && !isNewForm) || (!selectedTranche && isNewForm)))
    {
      fetchDocumentationData();
    }
  
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, selectedTranche, selectedTrancheToCopy, isNewForm]);

  useEffect(() =>
  {
    const fetchDocumentationFiles = async () =>
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await axios({
        method: "post",
        url: "/GetDocuments",
        data: {
          Pathname: location?.pathname,
          TrancheID: selectedTrancheToCopy?.id || selectedTranche?.id
        }
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const files = data?.map(({ 
            File_Name: name, 
            File_Id: id, 
            File_Location: location, 
            Tab_Type: tabType, 
            File_Type: fileType,
            Doc_Type: docName, 
            isApproved: status, 
            Approve: markedForApproval
          }) => ({
            file: { name, id, location, tabType, fileType, status, markedForApproval },
            docName
          })) || [];
          
          setFiles(files);
        } 
        else if (status === 400)
        {
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
  
          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Get Documentation Documents Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    if (selectedPFI && (selectedTrancheToCopy || selectedTranche)) 
    {
      fetchDocumentationFiles();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, selectedTranche, selectedTrancheToCopy, isNewForm, toggleFileData]);
  
  useImperativeHandle(ref, () => ({
    handleSubmit
  }));

  const convertToDayJsObject = (date) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
    return convertedDate;
  }

  const calculateMaturityDate = (counterPartyType) =>
  {
    const shipmentDate = formData?.Shipment_Date ? dayjs(formData.Shipment_Date) : null;
    const tenorDays = formData?.[counterPartyType === "Customer" ? "Selling_Tenor_Days" : "Buying_Tenor_Days"] || 0;
    const maturityDate = shipmentDate ? shipmentDate.add(tenorDays, 'day') : null;

    return maturityDate;
  }

  const handleUpload = async (Tranche_Id) => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileFormData = new FormData();
    const TrancheID = Tranche_Id || selectedTranche?.value;

    uploadedFiles.forEach((file, index) => 
    {
      const fileObj = {
        DealPFIId: selectedPFI,
        TrancheID: TrancheID || selectedTranche?.id,
        fileName: file.name,
        docType: `Documentation - ${TrancheID}`,
        fileExtension: file.name.split(".").pop(),
        tabType: file.tabType,
        fileType: file.docType,
        markForApproval: file.markForApproval
      };
    
      fileFormData.append(`file${index}`, JSON.stringify(fileObj));
      fileFormData.append(`file${index}`, file);
    });    

    fileFormData.append("pathname", location?.pathname);

    await axios({
      method: "post",
      url: "/UploadDocument",
      data: fileFormData
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        setAlertMessage("Data saved successfully.");
        setAlertType("success");
        setShowAlert(true);
      
        setTimeout(() => 
        {
          if (isNewForm) 
          {
            setToggleFilterData(!toggleFilterData);
          }

          setUploadedFiles([]);
          setIsExistingDocumentation(true);
          setIsDataUpdated(false);
          setIsFormSaved(true);
          setToggleFileData(!toggleFileData);
        }, 3000);
      } 
      else if (status === 202)
      {
        setAlertMessage(data?.data || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);        
      }
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Upload Documentation Documents Api: ", error);
      setIsLoading(false);
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleSelectTranche = (flag, newValue) =>
  {
    if (flag === "Copy")
    {
      setSelectedTrancheToCopy(newValue);
    }
    else
    {
      setSelectedTranche(newValue);
      setSelectedTrancheToCopy(null)
      setIsNewForm(false);
    }

    setFormData(dataSchema);
  }

  const handleChange = (event, field, value, isDate = false) => 
  {
    let newValue = value;

    if (isDate && newValue)
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }

    if (["Advance_Amount", "Discount", "Freight", "FOB_Value", "Value"].includes(field))
    {
      // Remove any non-numeric characters except the decimal point
      newValue = value.replace(/[^0-9.]/g, "");

      // Ensure the value has at most 2 decimal places
      if (newValue.includes(".")) 
      {
        const [integerPart, decimalPart] = newValue.split(".");
        newValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
      }
    }

    if (field === "Freight" || field === "FOB_Value")
    {
      freightInputRef?.current?.setCustomValidity("");
      fobValueInputRef?.current?.setCustomValidity("");

      let freight = parseInt(formData["Freight"]) || 0;
      let fobValue = parseInt(formData["FOB_Value"]) || 0;

      if (field === "Freight")
      {
        freight = parseInt(newValue);
      }
      else if (field === "FOB_Value")
      {
        fobValue = parseInt(newValue);
      }

      const calculatedTotalAmount = freight + fobValue;
      const actualTotalAmount = formData["Total_Amount"] || 0;

      if (calculatedTotalAmount > actualTotalAmount)
      {
        event?.target?.setCustomValidity("The calculated total is more than the actual total amount.");
      }
      else if (calculatedTotalAmount < actualTotalAmount)
      {
        event?.target?.setCustomValidity("The calculated total is less than the actual total amount.");
      }
    }

    setFormData((previousFormData) => ({
      ...previousFormData,
      [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null
    }));

    if (isExistingDocumentation) 
    {
      setIsDataUpdated(true);
    }

    setIsFormSaved(false);
  }

  const handleSubmit = async (event) => 
  {
    event?.preventDefault();

    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    let updatedFormData = {
      ...formData,
      Business_Unit_Id: selectedBusinessUnit?.id,
      Deal_PFI_Id: selectedPFI,
      User_Id: currentUser?.id,
      ...(!isExistingDocumentation && { Tranche_Id: selectedTranche?.id })
    };

    updatedFormData = Object.fromEntries(Object.entries(updatedFormData).map(([key, value]) => [
      key,
      typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
    ]));

    const apiUrl = isExistingDocumentation  ? "/UpdateDocumentation" : "/AddDocumentation";

    if (isExistingDocumentation && !isWritable && isDocumentWritable)
    {
      if (uploadedFiles.length > 0)
      {
        handleUpload(selectedTranche?.id);
      }
      else
      {
        setIsLoading(false);
      }
    }
    else
    {
      await axios({
        method: "post",
        url: apiUrl,
        data: updatedFormData
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          if (!isExistingDocumentation) 
          {
            setToggleFilterData(!toggleFilterData);
          } 
          else 
          {
            // Update tranches with the new tranche name if it is different
            setTranches((previousTranches) => 
            {
              let updatedTranches = previousTranches.map((tranche) => 
              {
                if (tranche.id === selectedTranche?.id && tranche.value !== formData?.Tranche_Name) 
                {
                  return { ...tranche, value: formData?.Tranche_Name };
                }

                return tranche;
              });

              // Set selected tranche value if updated
              if (selectedTranche?.id && formData?.Tranche_Name) 
              {
                const selectedTrancheUpdated = updatedTranches.find((tranche) => tranche.id === selectedTranche.id);
                
                if (selectedTrancheUpdated?.value !== selectedTranche.value) 
                {
                  setSelectedTranche({
                    ...selectedTranche,
                    value: formData?.Tranche_Name
                  });
                }
              }

              return updatedTranches;
            });
          }

          if (uploadedFiles.length > 0) 
          {
            const Tranche_Id = response?.data?.Tranche_Id || selectedTranche?.id;

            if (Tranche_Id) 
            {
              handleUpload(Tranche_Id);
            }
          } 
          else 
          {
            setIsLoading(false);
            setAlertMessage("Data saved successfully.");
            setAlertType("success");
            setShowAlert(true);

            setTimeout(() => 
            {
              if (isNewForm) 
              {
                setToggleFilterData(!toggleFilterData);
              }

              setUploadedFiles([]);
              setIsExistingDocumentation(true);
              setIsDataUpdated(false);
              setIsFormSaved(true);
              setToggleFileData(!toggleFileData);
            }, 3000);
          }

          setIsFormSavedOrDeleted(true);
          setIsUpdated(false);
        } 
        else if (status === 400)
        {
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);

          if (data?.is_deal_locked)
          {
            setIsUpdated(false);
            setActiveTab("Deal No.");
          }
        }
        else 
        {
          setIsLoading(false);
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.log("Add/Update Documentation Data Api: ", error);
        setIsLoading(false);
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }
  }

  const handleAddTrancheWrapper = () => 
  {
    const isEmpty = Object.entries(formData)?.every(([key, value]) => value === "" || value === null || value === undefined) && uploadedFiles.length === 0;
    const isAnyFormFieldFilled = Object.values(formData).some(value => 
    {
      if (Array.isArray(value)) 
      {
        return value.some(nestedObject => Object.values(nestedObject).some(nestedValue => Boolean(nestedValue)));
      }
      
      return Boolean(value);
    });

    if (!isAnyFormFieldFilled)
    {
      setShowWarningDialog(true);
    }
    else if (!isEmpty && !isFormSaved && (!isExistingDocumentation || isDataUpdated)) 
    {
      setShowAddModal(true);
    } 
    else 
    {
      handleAddTranche();
    }
  }

  const handleAddTranche = () => 
  {
    setShowAddModal(false);

    const resetFormData = {
      AWB_from_Supplier: "",
      Supplier_Document_Receiving_Date: null,
      Disc_Acceptance: "",
      Received_Payment: "",
      AWB_To_Customer: "",
      Dispatch_Date: null,
      Discounting_Required: null,
      Customer_Transaction_Status: null,
      Supplier_Transaction_Status: null,
      Advance_Amount: null,
      Discount: null,
      Freight: null,
      FOB_Value: null,
      Label: "",
      Value: null,
      Transaction_Type: null,
      Supplier_Status: null,
      Supplier_Discrepancy_Reason: "",
      Supplier_Discrepancy_Acceptance_Swift: "",
      Customer_Status: null,
      Customer_Discrepancy_Reason: "",
      Customer_Discrepancy_Acceptance_Swift: "",
      Special_Requirement: "",
      Special_Requirement_SI: "",
      Special_Requirement_CI: "",
      Special_Requirement_SA: "",
      Special_Requirement_PL: "",
      Special_Requirement_BE: "",
      Target_Customer_PFI_Issuance_Date: null,
      Target_Supplier_LC_Issuance_Date: null,
      Target_Customer_NN_Documents: null,
      Target_Supplier_NN_Documents: null,
      Target_Supplier_Original_Documents: null,
      Target_Customer_Original_Documents: null,
      Customer_LC_Deadline: null
    };
    formRef?.current?.reset();

    setSelectedTranche(null);
    setFiles([]);
    setUploadedFiles([]);
    setInitialFormData(JSON.parse(JSON.stringify(resetFormData)));
    setFormData(resetFormData);
    setIsExistingDocumentation(false);
    setIsDataUpdated(false);
    setIsNewForm(true);
    setIsUpdated(false);
  }

  const handleDelete = async () => 
  {
    setShowDeleteModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileIds = files.map((file) => file.file.id);

    await axios({
      method: "post",
      url: "/DeleteDocumentation",
      data: {
        Business_Unit_Id: selectedBusinessUnit?.id,
        Deal_PFI_Id: selectedPFI,
        Tranche_Id:  selectedTranche?.id
      }
    })
    .then(async (response) => 
    {
      const { status, data } = response;

      if (status === 200) 
      {
        await axios({
          method: "post",
          url: "/DeleteDocument",
          data: { 
            pathname: location?.pathname,
            fileIds: fileIds
          }
        })
        .then((response) => 
        {
          setIsLoading(false);
          const { status } = response;

          if (status === 200) 
          {
            const updatedtranches = tranches.filter((tranche) => tranche?.id !== selectedTranche?.id) || [];
            formRef?.current?.reset();

            if (updatedtranches.length > 0) 
            {
              setSelectedTranche(updatedtranches[0]);
            } 
            else 
            {
              setSelectedTranche(null);
            }

            setTranches(updatedtranches);
            setFormData((previousFormData) => ({
              ...previousFormData,
              AWB_from_Supplier: "",
              Supplier_Document_Receiving_Date: null,
              Disc_Acceptance: "",
              Received_Payment: "",
              AWB_To_Customer: "",
              Dispatch_Date: null,
              Discounting_Required: null,
              Customer_Transaction_Status: null,
              Supplier_Transaction_Status: null,
              Advance_Amount: null,
              Discount: null,
              Freight: null,
              FOB_Value: null,
              Label: "",
              Value: null,
              Transaction_Type: null,
              Supplier_Status: "",
              Supplier_Discrepancy_Reason: "",
              Supplier_Discrepancy_Acceptance_Swift: "",
              Customer_Status: "",
              Customer_Discrepancy_Reason: "",
              Customer_Discrepancy_Acceptance_Swift: "",
              Special_Requirement: "",
              Special_Requirement_SI: "",
              Special_Requirement_CI: "",
              Special_Requirement_SA: "",
              Special_Requirement_PL: "",
              Special_Requirement_BE: "",
              Target_Customer_PFI_Issuance_Date: null,
              Target_Supplier_LC_Issuance_Date: null,
              Target_Customer_NN_Documents: null,
              Target_Supplier_NN_Documents: null,
              Target_Supplier_Original_Documents: null,
              Target_Customer_Original_Documents: null,
              Customer_LC_Deadline: null
            }));
            setFiles([]);
            setUploadedFiles([]);
            setIsExistingDocumentation(false);
            setIsDataUpdated(false);
            setIsFormSaved(false);
            setIsFormSavedOrDeleted(true);
            setIsUpdated(false);
          } 
          else 
          {
            setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
          }
        })
        .catch((error) => 
        {
          console.log("Delete Documentation Documents Api: ", error);
          setIsLoading(false);
          
          const status = error?.response?.status;

          if (status === 403) 
          {
            triggerSessionExpire();
          } 
          else 
          {
            setAlertMessage(
              status === 401
                ? "Unauthorized access. You do not have the required permissions to perform this action."
                : status === 429
                ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                : "An error occurred while processing your request. Please try again later or contact the site administrator."
            );
            setAlertType("error");
            setShowAlert(true);
        
            if (status === 429) 
            {
              setTimeout(logOut, 3000);
            }
          }
        });
      } 
      else if (status === 400)
      {
        setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);

        if (data?.is_deal_locked)
        {
          setIsUpdated(false);
          setActiveTab("Deal No.");
        }
      }
      else 
      {
        setIsLoading(false);
        setAlertMessage(data?.data);
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Delete Documentation Data Api: ", error);
      setIsLoading(false);
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleShowDocumentModal = (documentType, downloadButtonRef) =>
  {
    setToggleDocumentList(!toggleDocumentList);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");
    setDocumentType(documentType);
    
    if (documentType !== "COO")
    {
      const selectedDocumentRevisionNumbers = [
        ...new Set(
          documentRevisionNumbers
            .filter(documentRevisionNumber => 
              documentRevisionNumber.Document_Type === documentType &&
              (documentType === "PFI" || documentRevisionNumber.Tranche_Id === selectedTranche?.id)
            )
        )
      ] || [];
      const { 
        is_document_generated: isDocumentGenerated = false, 
        is_document_approved: isDocumentApproved = false 
      } = documentGenerationData[documentType];

      if (selectedDocumentRevisionNumbers.length > 0 || isDocumentGenerated)
      {
        const approvedDocumentRevisionNumbers = selectedDocumentRevisionNumbers.filter(documentRevisionNumber => documentRevisionNumber.isApproved);  
        
        if (isDocumentGenerated)
        {
          selectedDocumentRevisionNumbers.unshift({ id: "Default", value: "Default" });

          if (isDocumentApproved)
          {
            approvedDocumentRevisionNumbers.unshift({ id: "Default", value: "Default" });
          }
        }

        setDraftDocuments(selectedDocumentRevisionNumbers);
        setApprovedDocuments(approvedDocumentRevisionNumbers);
      }

      setSelectedDocumentGenerationButtonRef(downloadButtonRef);
      setShowDocumentModal(true);
    }
    else
    {
      const approvedCertificateDocuments = coos.filter(coo => coo.isApproved);
        
      setDraftDocuments(coos);
      setApprovedDocuments(approvedCertificateDocuments);
      setSelectedDocumentGenerationButtonRef(downloadButtonRef);
      setShowDocumentModal(true);
    }
  }

  const handleTabChange = (tab) =>
  {
    setSelectedDocumentRevisionNumber(null);
    setSelectedCOO(null);
    setSelectedTab(tab);
  }

  const handleViewDocument = async () =>
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/ViewDocument",
      data: { 
        Pathname: location?.pathname,
        Deal_PFI_Id: selectedPFI,
        Tranche_Id:  selectedTranche?.id,
        Document_Type: documentType,
        COO_ID: selectedCOO?.id,
        Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
        is_approved_document: selectedTab === 1,
        view_document: true
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        window.open(data, '_blank', 'noopener,noreferrer');
      }
      else if (status === 202)
      {
        setAlertMessage(data?.data);
        setAlertType("info");
        setShowAlert(true);
      }
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("View Document Api: ", error);
      setIsLoading(false);

      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleCloseDialog = () => 
  {
    setShowWarningDialog(false);
  }
  
  const handleCloseModal = () => 
  {
    setDraftDocuments([]);
    setApprovedDocuments([]);
    setSelectedDocumentRevisionNumber(null);
    setAddTermsAndConditionsPage(false);
    setSelectedTab(0);
    setSelectedCOO(null);
    setShowDeleteModal(false);
    setShowAddModal(false);
    setShowDocumentModal(false);
  }

  return (
    <div>
      <div className = "d-flex align-items-center justify-content-between gap-2 w-100 mb-2">
        <Typography variant = "h5" gutterBottom className = "page-heading m-0">
          Documentation Information
        </Typography> 

        <div className = "documentation report-buttons d-flex align-items-center flex-wrap gap-1">
          {documentPermissions["PFI"]?.isReadable && (
            <ButtonGroup variant = "contained">
              <Button sx = {{ cursor: 'default !important' }}>PFI</Button>

              <IconButton 
                color = "primary" 
                size = "small"
                disabled = {!selectedPFI}
                onClick = {() => handleShowDocumentModal("PFI", pfiDocumentDownloadButtonRef)}
                sx = {{
                  margin: '5px !important' 
                }}
              >
                <FileDownloadIcon fontSize = "small" />
              </IconButton>
            </ButtonGroup>
          )}

          {documentPermissions["SI"]?.isReadable && (
            <ButtonGroup variant = "contained">
              <Button sx = {{ cursor: 'default !important' }}>SI</Button>

              <IconButton 
                color = "primary" 
                size = "small"
                disabled = {!selectedTranche}
                onClick = {() => handleShowDocumentModal("SI", siDocumentDownloadButtonRef)}
                sx = {{
                  margin: '5px !important' 
                }}
              >
                <FileDownloadIcon fontSize = "small" />
              </IconButton>
            </ButtonGroup>
          )}

          {documentPermissions["CI"]?.isReadable && (
            <ButtonGroup variant = "contained">
              <Button sx = {{ cursor: 'default !important' }}>CI</Button>

              <IconButton 
                color = "primary" 
                size = "small"
                disabled = {!selectedTranche}
                onClick = {() => handleShowDocumentModal("CI", ciDocumentDownloadButtonRef)}
                sx = {{
                  margin: '5px !important' 
                }}
              >
                <FileDownloadIcon fontSize = "small" />
              </IconButton>
            </ButtonGroup>
          )}

          {documentPermissions["SA"]?.isReadable && (
            <ButtonGroup variant = "contained">
              <Button sx = {{ cursor: 'default !important' }}>SA</Button>

              <IconButton 
                color = "primary" 
                size = "small"
                disabled = {!selectedTranche}
                onClick = {() => handleShowDocumentModal("SA", saDocumentDownloadButtonRef)}
                sx = {{
                  margin: '5px !important' 
                }}
              >
                <FileDownloadIcon fontSize = "small" />
              </IconButton>
            </ButtonGroup>
          )}

          {documentPermissions["PL"]?.isReadable && (
            <ButtonGroup variant = "contained">
              <Button sx = {{ cursor: 'default !important' }}>PL</Button>

              <IconButton 
                color = "primary" 
                size = "small"
                disabled = {!selectedTranche}
                onClick = {() => handleShowDocumentModal("PL", plDocumentDownloadButtonRef)}
                sx = {{
                  margin: '5px !important' 
                }}
              >
                <FileDownloadIcon fontSize = "small" />
              </IconButton>
            </ButtonGroup>
          )}

          {documentPermissions["BE"]?.isReadable && (
            <ButtonGroup variant = "contained">
              <Button sx = {{ cursor: 'default !important' }}>BE</Button>

              <IconButton 
                color = "primary" 
                size = "small"
                disabled = {!selectedTranche}
                onClick = {() => handleShowDocumentModal("BE", beDocumentDownloadButtonRef)}
                sx = {{
                  margin: '5px !important' 
                }}
              >
                <FileDownloadIcon fontSize = "small" />
              </IconButton>
            </ButtonGroup>
          )}

          {/* <ButtonGroup variant = "contained">
            <Button sx = {{ cursor: 'default !important' }}>PO</Button>

            <IconButton 
              color = "primary" 
              size = "small"
              disabled = {!selectedTranche}
              onClick = {() => handleShowDocumentModal("PO", poDocumentDownloadButtonRef)}
              sx = {{
                margin: '5px !important' 
              }}
            >
              <FileDownloadIcon fontSize = "small" />
            </IconButton>
          </ButtonGroup> */}

          {documentPermissions["COO"]?.isReadable && (
            <ButtonGroup variant = "contained">
              <Button sx = {{ cursor: 'default !important' }}>Other Certificates</Button>

              <IconButton 
                color = "primary" 
                size = "small"
                disabled = {!selectedTranche}
                onClick = {() => handleShowDocumentModal("COO", cooDocumentDownloadButtonRef)}
                sx = {{
                  margin: '5px !important' 
                }}
              >
                <FileDownloadIcon fontSize = "small" />
              </IconButton>
            </ButtonGroup>
          )}

          <ReportDownloader
            ref = {pfiDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/GeneratePFI"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit?.id,
              Deal_PFI_Id: selectedPFI,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              add_terms_and_conditions_page: addTermsAndConditionsPage,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            fileName = "PFI"
            buttonText = "Generate PFI"
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            ref = {siDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/GenerateSI"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit?.id,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id:  selectedTranche?.id,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            fileName = "SI"
            buttonText = "Generate SI"
            isDisabled  =  {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            ref = {ciDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/GenerateCI"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit?.id,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate CI"
            fileName = "CI"
            isDisabled = {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            ref = {saDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/GenerateSA"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit?.id,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate SA"
            fileName = "SA"
            isDisabled = {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            ref = {plDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/GeneratePL"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit?.id,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate PL"
            fileName = "PL"
            isDisabled = {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            ref = {beDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/GenerateBE"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit?.id,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate BE"
            fileName = "BE"
            isDisabled = {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          {/* <ReportDownloader
            ref = {poDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/GeneratePO"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit?.id,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate PO"
            fileName = "PO"
            isDisabled = {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          /> */}

          <ReportDownloader
            ref = {cooDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/GenerateOtherCertificates"
            apiData = {{ 
              Business_Unit_Id: selectedBusinessUnit?.id,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              COO_ID: selectedCOO?.id,
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate Other Certificate"
            fileName = "COO"
            isDisabled  =  {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />
        </div>
      </div>
      
      <div className = "input-fields p-2">
        <div className = "d-flex justify-content-end align-items-center my-4">
          <div className = "multi-form autocomplete">
            <VirtualizedAutocomplete
              isMultiple = {false}
              isObject = {true}
              isWritable = {isWritable}
              filterOn = "Select Tranche to Auto Populate"
              options = {tranchesToCopy}
              selectedOptions = {selectedTrancheToCopy}
              handleSelectChange = {(filterOn, newValue) => handleSelectTranche("Copy", newValue)}
            />
          
            <VirtualizedAutocomplete
              isMultiple = {false}
              isObject = {true}
              isWritable = {true}
              filterOn = "Select Tranche Name"
              options = {tranches}
              selectedOptions = {selectedTranche || null}
              handleSelectChange = {(filterOn, newValue) => handleSelectTranche("Select", newValue)}
            />

            <IconButton
              className = "add_button"
              color = "primary"
              aria-label = "add"
              disabled = {!isWritable}
              onClick = {handleAddTrancheWrapper}
            >
              <AddIcon />
            </IconButton>
          </div>
        </div>

        <form
          className = "progress_form_con multi-form-container"
          onSubmit = {handleSubmit}
        >
          <div className = "d-flex flex-column gap-3">
            <Grid
              container
              spacing = {2}
              className = "align-items-start autocomplete"
            >
              <Grid size = {{ xs: 4 }} className = "form-text-field"></Grid>

              <Grid size = {{ xs: 4 }} className = "mt-3">
                <TextField
                  label = "Tranche Name"
                  id = "Tranche_Name"
                  value = {formData?.Tranche_Name || ""}
                  required
                  onChange = {(event) => handleChange(event, "Tranche_Name", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 4 }} className = "form-text-field"></Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "AWB from Supplier"
                  id = "AWB_from_Supplier"
                  value = {formData?.AWB_from_Supplier || ""}
                  onChange = {(event) => handleChange(event, "AWB_from_Supplier", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Supplier Document Receiving Date"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Supplier_Document_Receiving_Date)}
                    onChange = {(newDate) => handleChange(null, "Supplier_Document_Receiving_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange(null, "Supplier_Document_Receiving_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "AWB to Customer"
                  id = "AWB_To_Customer"
                  value = {formData?.AWB_To_Customer || ""}
                  onChange = {(event) => handleChange(event, "AWB_To_Customer", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Dispatch Date"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-full"
                    value = {convertToDayJsObject(formData?.Dispatch_Date)}
                    onChange = {(newDate) => handleChange(null, "Dispatch_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange(null, "Dispatch_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Disc. Acceptance"
                  id = "Disc_Acceptance"
                  value = {formData?.Disc_Acceptance || ""}
                  onChange = {(event) => handleChange(event, "Disc_Acceptance", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>
              
              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  isWritable = {isWritable}
                  filterOn = "Received Payment"
                  options = {receivedPayments}
                  selectedOptions = {formData?.Received_Payment || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange(null, "Received_Payment", newValue)}
                />
              </Grid>

              {formData?.Selling_Payment_Term === "ADVANCE PAYMENT" ? (
                <>
                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Advance Receiving Date"
                        disableMaskedInput
                        readOnly = {!isWritable}
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Advance_Receiving_Date)}
                        onChange = {(newDate) => handleChange(null, "Advance_Receiving_Date", newDate, true)}
                        onAccept = {(newDate) => handleChange(null, "Advance_Receiving_Date", newDate, true)}
                        slotProps = {{
                          actionBar: {
                            actions: ["clear"]
                          },
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Remaining Balance Receiving Date"
                        disableMaskedInput
                        readOnly = {!isWritable}
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Remaining_Balance_Receiving_Date)}
                        onChange = {(newDate) => handleChange(null, "Remaining_Balance_Receiving_Date", newDate, true)}
                        onAccept = {(newDate) => handleChange(null, "Remaining_Balance_Receiving_Date", newDate, true)}
                        slotProps = {{
                          actionBar: {
                            actions: ["clear"]
                          },
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              ) : (formData?.Selling_Payment_Term === "LC" && formData?.Selling_Tenor !== "Sight") && (
                <Grid size = {{ xs: 3 }} className = "form-text-field">
                  <LocalizationProvider dateAdapter = {AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat = "MM/DD/YYYY"
                      label = "Maturity Date for Customer"
                      disableMaskedInput
                      className = "date-picker w-full"
                      disabled = {true}
                      value = {calculateMaturityDate("Customer")}
                      slotProps = {{
                        textField: {
                          InputProps: {
                            size: "small"
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              )}

              {formData?.Buying_Payment_Term === "ADVANCE PAYMENT" ? (
                <>
                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Advance Payment Date"
                        disableMaskedInput
                        readOnly = {!isWritable}
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Advance_Payment_Date)}
                        onChange = {(newDate) => handleChange(null, "Advance_Payment_Date", newDate, true)}
                        onAccept = {(newDate) => handleChange(null, "Advance_Payment_Date", newDate, true)}
                        slotProps = {{
                          actionBar: {
                            actions: ["clear"]
                          },
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Remaining Balance Payment Date"
                        disableMaskedInput
                        readOnly = {!isWritable}
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Remaining_Balance_Payment_Date)}
                        onChange = {(newDate) => handleChange(null, "Remaining_Balance_Payment_Date", newDate, true)}
                        onAccept = {(newDate) => handleChange(null, "Remaining_Balance_Payment_Date", newDate, true)}
                        slotProps = {{
                          actionBar: {
                            actions: ["clear"]
                          },
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              ) : (formData?.Buying_Payment_Term === "LC" && formData?.Buying_Tenor !== "Sight") && (
                <Grid size = {{ xs: 3 }} className = "form-text-field">
                  <LocalizationProvider dateAdapter = {AdapterDayjs}>
                    <DesktopDatePicker
                      inputFormat = "MM/DD/YYYY"
                      label = "Maturity Date for Supplier"
                      disableMaskedInput
                      className = "date-picker w-full"
                      disabled = {true}
                      value = {calculateMaturityDate("Supplier")}
                      slotProps = {{
                        textField: {
                          InputProps: {
                            size: "small"
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              )}

              <Grid size = {{ xs: 3 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  isWritable = {isWritable}
                  filterOn = "Discounting Required"
                  options = {discountingOptions}
                  selectedOptions = {formData?.Discounting_Required || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange(null, "Discounting_Required", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Customer Transaction Status"
                  id = "Customer_Transaction_Status"
                  value = {formData?.Customer_Transaction_Status || ""}
                  onChange = {(event) => handleChange(event, "Customer_Transaction_Status", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 3 }} className = "form-text-field">
                <TextField
                  label = "Supplier Transaction Status"
                  id = "Supplier_Transaction_Status"
                  value = {formData?.Supplier_Transaction_Status || ""}
                  onChange = {(event) => handleChange(event, "Supplier_Transaction_Status", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 4 }} className = "form-text-field">
                <TextField
                  label = "Special Requirement"
                  id = "Special_Requirement"
                  multiline
                  rows = {3}
                  value = {formData?.Special_Requirement || ""}
                  onChange = {(event) => handleChange(event, "Special_Requirement", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 12 }}>
                <hr />

                <div className = "w-100 d-flex justify-content-between mb-4">
                  <h3 className = "mb-0">CI Document Values</h3>
                </div>

                <Grid
                  container
                  spacing = {2}
                  className = "align-items-start"
                >
                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      label = "Advance Amount"
                      id = "Advance_Amount"
                      type = "number"
                      value = {formData?.Advance_Amount || ""}
                      onChange = {(event) => handleChange(event, "Advance_Amount", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      label = "Discount"
                      id = "Discount"
                      type = "number"
                      value = {formData?.Discount || ""}
                      onChange = {(event) => handleChange(event, "Discount", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      label = "Total Amount"
                      id = "Total_Amount"
                      type = "number"
                      value = {formData?.Total_Amount || ""}
                      disabled
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        },
                        inputLabel: { 
                          shrink: formData?.Total_Amount 
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      inputRef = {freightInputRef}
                      label = "Freight"
                      id = "Freight"
                      type = "number"
                      value = {formData?.Freight || ""}
                      onChange = {(event) => handleChange(event, "Freight", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      inputRef = {fobValueInputRef}
                      label = "FOB Value"
                      id = "FOB_Value"
                      type = "number"
                      value = {formData?.FOB_Value || ""}
                      onChange = {(event) => handleChange(event, "FOB_Value", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>
                  
                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      label = "Label"
                      id = "Label"
                      value = {formData?.Label || ""}
                      onChange = {(event) => handleChange(event, "Label", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <TextField
                      label = "Value"
                      id = "Value"
                      type = "number"
                      value = {formData?.Value || ""}
                      onChange = {(event) => handleChange(event, "Value", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 3 }}>
                    <VirtualizedAutocomplete
                      isMultiple = {false}
                      isObject = {false}
                      isWritable = {isWritable}
                      filterOn = "Transaction Type"
                      options = {transactionTyoes}
                      selectedOptions = {formData?.Transaction_Type || null}
                      handleSelectChange = {(filterOn, newValue) => handleChange(null, "Transaction_Type", newValue)}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid size = {{ xs: 12 }}>
                <hr />

                <div className = "w-100 d-flex justify-content-between mb-4">
                  <h3 className = "mb-0">Special Requirements</h3>
                </div>

                <Grid
                  container
                  spacing = {2}
                  className = "align-items-start"
                >
                  <Grid size = {{ xs: 4 }} className = "form-text-field">
                    <TextField
                      label = "SI Special Requirement"
                      id = "Special_Requirement_SI"
                      multiline
                      rows = {3}
                      value = {formData?.Special_Requirement_SI || ""}
                      onChange = {(event) => handleChange(event, "Special_Requirement_SI", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 4 }} className = "form-text-field">
                    <TextField
                      label = "CI Special Requirement"
                      id = "Special_Requirement_CI"
                      multiline
                      rows = {3}
                      value = {formData?.Special_Requirement_CI || ""}
                      onChange = {(event) => handleChange(event, "Special_Requirement_CI", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 4 }} className = "form-text-field">
                    <TextField
                      label = "SA Special Requirement"
                      id = "Special_Requirement_SA"
                      multiline
                      rows = {3}
                      value = {formData?.Special_Requirement_SA || ""}
                      onChange = {(event) => handleChange(event, "Special_Requirement_SA", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 4 }} className = "form-text-field">
                    <TextField
                      label = "PL Special Requirement"
                      id = "Special_Requirement_PL"
                      multiline
                      rows = {3}
                      value = {formData?.Special_Requirement_PL || ""}
                      onChange = {(event) => handleChange(event, "Special_Requirement_PL", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>

                  <Grid size = {{ xs: 4 }} className = "form-text-field">
                    <TextField
                      label = "BE Special Requirement"
                      id = "Special_Requirement_BE"
                      multiline
                      rows = {3}
                      value = {formData?.Special_Requirement_BE || ""}
                      onChange = {(event) => handleChange(event, "Special_Requirement_BE", event.target.value)}
                      slotProps = {{
                        input: {
                          readOnly: !isWritable
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid size = {{ xs: 12 }}>
                <hr />

                <div className = "w-100 d-flex justify-content-between mb-4">
                  <h3 className = "mb-0">Target Dates</h3>
                </div>

                <Grid
                  container
                  spacing = {2}
                  className = "align-items-start autocomplete"
                >
                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Customer PFI Issuance Date"
                        disableMaskedInput
                        readOnly = {!isWritable}
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Customer_PFI_Issuance_Date)}
                        onChange = {(newDate) => handleChange(null, "Target_Customer_PFI_Issuance_Date", newDate, true)}
                        onAccept = {(newDate) => handleChange(null, "Target_Customer_PFI_Issuance_Date", newDate, true)}
                        slotProps = {{
                          actionBar: {
                            actions: ["clear"]
                          },
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Supplier LC Issuance Date"
                        disableMaskedInput
                        readOnly = {!isWritable}
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Supplier_LC_Issuance_Date)}
                        onChange = {(newDate) => handleChange(null, "Target_Supplier_LC_Issuance_Date", newDate, true)}
                        onAccept = {(newDate) => handleChange(null, "Target_Supplier_LC_Issuance_Date", newDate, true)}
                        slotProps = {{
                          actionBar: {
                            actions: ["clear"]
                          },
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Customer NN Documents"
                        disableMaskedInput
                        readOnly = {!isWritable}
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Customer_NN_Documents)}
                        onChange = {(newDate) => handleChange(null, "Target_Customer_NN_Documents", newDate, true)}
                        onAccept = {(newDate) => handleChange(null, "Target_Customer_NN_Documents", newDate, true)}
                        slotProps = {{
                          actionBar: {
                            actions: ["clear"]
                          },
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Supplier NN Documents"
                        disableMaskedInput
                        readOnly = {!isWritable}
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Supplier_NN_Documents)}
                        onChange = {(newDate) => handleChange(null, "Target_Supplier_NN_Documents", newDate, true)}
                        onAccept = {(newDate) => handleChange(null, "Target_Supplier_NN_Documents", newDate, true)}
                        slotProps = {{
                          actionBar: {
                            actions: ["clear"]
                          },
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Supplier Original Documents"
                        disableMaskedInput
                        readOnly = {!isWritable}
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Supplier_Original_Documents)}
                        onChange = {(newDate) => handleChange(null, "Target_Supplier_Original_Documents", newDate, true)}
                        onAccept = {(newDate) => handleChange(null, "Target_Supplier_Original_Documents", newDate, true)}
                        slotProps = {{
                          actionBar: {
                            actions: ["clear"]
                          },
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Target Customer Original Documents"
                        disableMaskedInput
                        readOnly = {!isWritable}
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Target_Customer_Original_Documents)}
                        onChange = {(newDate) => handleChange(null, "Target_Customer_Original_Documents", newDate, true)}
                        onAccept = {(newDate) => handleChange(null, "Target_Customer_Original_Documents", newDate, true)}
                        slotProps = {{
                          actionBar: {
                            actions: ["clear"]
                          },
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid size = {{ xs: 3 }} className = "form-text-field">
                    <LocalizationProvider dateAdapter = {AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat = "MM/DD/YYYY"
                        label = "Customer LC Deadline"
                        disableMaskedInput
                        readOnly = {!isWritable}
                        className = "date-picker w-full"
                        value = {convertToDayJsObject(formData?.Customer_LC_Deadline)}
                        onChange = {(newDate) => handleChange(null, "Customer_LC_Deadline", newDate, true)}
                        onAccept = {(newDate) => handleChange(null, "Customer_LC_Deadline", newDate, true)}
                        slotProps = {{
                          actionBar: {
                            actions: ["clear"]
                          },
                          textField: {
                            InputProps: {
                              size: "small",
                              disabled: true
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>

              <Grid size = {{ xs: 12 }}>
                <hr />

                <div className = "w-100 d-flex justify-content-between mb-4">
                  <h3 className = "mb-0">Document Status</h3>
                </div>

                <Grid
                  container
                  spacing = {2}
                  className = "align-items-start autocomplete mb-2"
                >
                  <Grid size = {{ xs: 3 }}>
                    <VirtualizedAutocomplete
                      isMultiple = {false}
                      isObject = {false}
                      isWritable = {isWritable}
                      filterOn = "Supplier Status"
                      options = {buyerDocumentStatus}
                      selectedOptions = {formData?.Supplier_Status || null}
                      handleSelectChange = {(filterOn, newValue) => handleChange(null, "Supplier_Status", newValue)}
                    />
                  </Grid>

                  {formData?.Supplier_Status?.includes("Discrepant") && (
                    <>
                      <Grid size = {{ xs: 3 }}>
                        <VirtualizedAutocomplete
                          isMultiple = {false}
                          isObject = {false}
                          isWritable = {isWritable}
                          filterOn = "Supplier Discrepancy Acceptance Swift"
                          options = {discrepancyAcceptanceSwifts}
                          selectedOptions = {formData?.Supplier_Discrepancy_Acceptance_Swift || null}
                          handleSelectChange = {(filterOn, newValue) => handleChange(null, "Supplier_Discrepancy_Acceptance_Swift", newValue)}
                        />
                      </Grid>

                      <Grid size = {{ xs: 4 }} className = "form-text-field">
                        <TextField
                          label = "Supplier Discrepancy Reason"
                          id = "Supplier_Discrepancy_Reason"
                          multiline
                          rows = {3}
                          value = {formData?.Supplier_Discrepancy_Reason || ""}
                          onChange = {(event) => handleChange(event, "Supplier_Discrepancy_Reason", event.target.value)}
                          slotProps = {{
                            input: {
                              readOnly: !isWritable
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>

                <Grid
                  container
                  spacing = {2}
                  className = "align-items-start autocomplete"
                >
                  <Grid size = {{ xs: 3 }}>
                    <VirtualizedAutocomplete
                      isMultiple = {false}
                      isObject = {false}
                      isWritable = {isWritable}
                      filterOn = "Customer Status"
                      options = {sellerDocumentStatus}
                      selectedOptions = {formData?.Customer_Status || null}
                      handleSelectChange = {(filterOn, newValue) => handleChange(null, "Customer_Status", newValue)}
                    />
                  </Grid>

                  {formData?.Customer_Status?.includes("Discrepant") && (
                    <>
                      <Grid size = {{ xs: 3 }}>
                        <VirtualizedAutocomplete
                          isMultiple = {false}
                          isObject = {false}
                          isWritable = {isWritable}
                          filterOn = "Customer Discrepancy Acceptance Swift"
                          options = {discrepancyAcceptanceSwifts}
                          selectedOptions = {formData?.Customer_Discrepancy_Acceptance_Swift || null}
                          handleSelectChange = {(filterOn, newValue) => handleChange(null, "Customer_Discrepancy_Acceptance_Swift", newValue)}
                        />
                      </Grid>

                      <Grid size = {{ xs: 4 }} className = "form-text-field">
                        <TextField
                          label = "Customer Discrepancy Reason"
                          id = "Customer_Discrepancy_Reason"
                          multiline
                          rows = {3}
                          value = {formData?.Customer_Discrepancy_Reason || ""}
                          onChange = {(event) => handleChange(event, "Customer_Discrepancy_Reason", event.target.value)}
                          slotProps = {{
                            input: {
                              readOnly: !isWritable
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid size = {{ xs: 12 }}>
                <hr />

                <div className = "w-100 d-flex justify-content-between mb-3">
                  <h3>Upload Documents</h3>
                </div>

                <FileUploader
                  isWritable = {isWritable ? isDocumentWritable : isExistingDocumentation ? isDocumentWritable : false}
                  isLoading = {isLoading}
                  pathname = {location?.pathname}
                  files = {files}
                  uploadedFiles = {uploadedFiles}
                  allowFileTypeSelection = {true}
                  buyingLegFileTypes = {buyingLegFileTypes}
                  sellingLegFileTypes = {sellingLegFileTypes}
                  setIsLoading = {setIsLoading}
                  setShowAlert = {setShowAlert}
                  setAlertMessage = {setAlertMessage}
                  setAlertType = {setAlertType}
                  setFiles = {setFiles}
                  setUploadedFiles = {setUploadedFiles}
                  logOut = {logOut}
                />
              </Grid>
            </Grid>

            <button ref = {submitButtonRef} type = "submit" className = "hidden"></button>
          </div>
        </form>
      </div>

      <div className = "d-flex gap-2 justify-content-end submit-buttons">
        <button
          type = "button"
          variant = "contained"
          color = "primary"
          disabled = {(isExistingDocumentation ? !isDocumentWritable : !isWritable) || isLoading}
          className = "btn btn_secondary custom_btn"
          onClick = {() => submitButtonRef?.current?.click()}
        >
          {isExistingDocumentation  ? "Update" : "Save"}
        </button>

        {isExistingDocumentation  && (
          <button
            type = "button"
            variant = "contained"
            color = "error"
            disabled = {!isWritable || isLoading}
            className = "btn custom_btn btn-danger"
            onClick = {() => setShowDeleteModal(true)}
          >
            Delete
          </button>
        )}
      </div>

      <Dialog open = {showWarningDialog} onClose = {handleCloseDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            Please fill in at least one field in the form before adding a new entry.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type = "button"
            variant = "contained"
            className = "btn btn-primary custom_btn"
            onClick = {handleCloseDialog}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>

      <Modal show = {showDeleteModal} onHide = {handleCloseModal} centered className = "delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Do you really want to delete this tranche? This action will remove
            it from the documentations which cannot be undone.
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show = {showAddModal} onHide = {handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Adding a new form will discard your unsaved changes. Proceed
            anyway?
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleAddTranche}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
      
      <Modal show = {showDocumentModal} onHide = {handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{documentType === "COO" ? "Certificate" : documentType} Document</Modal.Title>
        </Modal.Header>
        <Modal.Body className = "pt-0 px-0">
          <Tabs 
            centered
            value = {selectedTab} 
            onChange = {(event, newValue) => handleTabChange(newValue)} 
            sx = {{ 
              minHeight: '38px !important',
              marginBottom: '1em',
              backgroundColor: '#eef2ff',
              '& .MuiTab-root': {
                minHeight: '38px !important'
              }
            }}
          >
            <Tab label = "Draft Documents" />
            <Tab label = "Approved Documents" />
          </Tabs>

          <div className = "px-3">
            <span>
              {selectedTab === 0 
                ? documentType === "COO" 
                  ? 'Select a draft document to generate or to view.'
                  : "Select a previous document to apply changes, view a document, or click 'Generate Draft Document' to create a new one."
                : 'Select an approved document to view.'
              }
            </span>            

            <div className = "mt-2">
              <VirtualizedAutocomplete
                isLoading = {documentType === "COO" ? areCOOsLoading : areDocumentRevisionNumbersLoading}
                isMultiple = {false}
                isObject = {true}
                isWritable = {true}
                filterOn = {documentType === "COO" ? "Select Certificate" : "Select Document Revision Number"}
                options = {selectedTab === 0 ? draftDocuments : approvedDocuments}
                selectedOptions = {documentType === "COO" ? selectedCOO : selectedDocumentRevisionNumber || null}
                handleSelectChange = {(filterOn, newValue) => documentType === "COO" ? setSelectedCOO(newValue) : setSelectedDocumentRevisionNumber(newValue)}
              />
            </div>

            {(documentType === "PFI" && selectedTab === 0) && (
              <div className = "d-flex align-items-center gap-1">
                <Checkbox 
                  size = "small"
                  className = "p-0"
                  disabled = {!isWritable}
                  onChange = {(event) => setAddTermsAndConditionsPage(event.target.checked)}
                />

                <Typography fontSize = "small">Include <b>Terms & Conditions</b> with the document?</Typography>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button 
            variant = "contained" 
            size = "small" 
            disabled = {documentType === "COO" ? (!selectedCOO || (selectedTab === 0 && !selectedCOO.isGenerated)) : !selectedDocumentRevisionNumber}
            onClick = {handleViewDocument}
          >
            View Document
          </Button>
          {selectedTab === 0 && (
            <Button 
              variant = "contained" 
              size = "small" 
              disabled = {!isWritable || (selectedTab === 1 && documentType !== "COO" && !selectedDocumentRevisionNumber) || (documentType === "BE" && !documentGenerationData?.["CI"]?.is_document_generated) || (documentType === "COO" && !selectedCOO)}
              onClick = {() => selectedDocumentGenerationButtonRef?.current?.click()}
            >
              Generate Draft Document
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default Documentation;