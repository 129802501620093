import { roles } from '../../utility/roles';
import Typography from '@mui/material/Typography';
import withAuth from '../../lib/withAuth';

const Ratings = ({ logOut = () => {} }) => 
{
  return (
    <div className = "content-container d-flex flex-column container kyc">
      <Typography variant = "h5" gutterBottom className = "page-heading m-0">
        Ratings
      </Typography>
    </div>
  );
}

export default withAuth(Ratings)([
  roles[7], 
  roles[19]
]);