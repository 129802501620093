import { memo, useEffect, useRef, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { CitySelect, CountrySelect, GetCountries, GetState, StateSelect } from 'react-country-state-city';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PhoneInput from 'react-phone-number-input';
import CustomPhoneInput from './CustomPhoneInput';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Modal from 'react-bootstrap/Modal';
import VirtualizedAutocomplete from './Autocomplete';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import 'react-country-state-city/dist/react-country-state-city.css';
import 'react-phone-number-input/style.css';

const Form = ({ show = false, title = "", isLoading = false, columns = [], selectedRow = {}, isWritable = false, dropdowns = {}, rowType = null, handleSubmitEditedRow = () => {}, handleSubmitAddedRow = () => {}, handleClose = () => {} }) => 
{
    /* LOCATION VARIABLES */
    const [country, setCountry] = useState({});
    const [state, setState] = useState({});

    /* FORM VARIABLES */
    const [formData, setFormData] = useState({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const phoneInputRef = useRef(null);
    const submitButtonRef = useRef(null);

    useEffect(() => 
    {
        if (Object.keys(selectedRow).length > 0) 
        {
            setFormData(selectedRow);
        }
    }, [selectedRow]);

    const convertToDayJsObject = (date) => 
    {
        const convertedDate = date ? dayjs(date, "YYYY-MM-DD HH:mm:ss") : null;
        return convertedDate;
    }
    
    const handleValidation = (field, value) => 
    {
        const errors = {};

        if (["Email_Address", "Email"].includes(field)) 
        {
            const emailPattern = new RegExp(/^(('[\w\s-]+')|([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

            if (value && !emailPattern.test(value)) 
            {
                errors[field] = "Please enter a valid email.";
            }
            else 
            {
                delete errors[field];
            }
        }
        else if (["Mobile_Number", "Contact_Number"].includes(field)) 
        {
            if (value) 
            {
                const mobileNumberObj = parsePhoneNumberFromString(value, country.iso2);

                if (!mobileNumberObj?.isValid()) 
                {
                    errors[field] = "Please enter a valid phone number.";
                }
            }
            else 
            {
                delete errors[field];
            }
        }

        if (Object.keys(errors).length === 0) 
        {
            setValidationErrors({});
        }
        else 
        {
            setValidationErrors((previousValidationErrors) => ({
                ...previousValidationErrors,
                ...errors
            }));
        }
    }

    const handleTextChange = async (field, value) =>
    {
        const trimmedValue = value?.trim() || "";

        if (!trimmedValue) 
        {
            if (field === "Country") 
            {
                setCountry(trimmedValue);
            }
            else if (field === "State") 
            {
                setState(trimmedValue);
            }

            setFormData((previousFormData) => ({
                ...previousFormData,
                [field]: trimmedValue
            }));

            return;
        }

        let validatedValue = trimmedValue;

        if (field === "Country") 
        {
            const countries = await GetCountries();

            validatedValue = countries.find((country) => country.name === trimmedValue) ? trimmedValue : "";
            setCountry(validatedValue);
        } 
        else if (field === "State") 
        {
            const states = await GetState();

            validatedValue = states.find((state) => state.name === trimmedValue) ? trimmedValue : "";
            setState(validatedValue);
        }

        setFormData((previousFormData) => ({
            ...previousFormData,
            [field]: validatedValue
        }));
    }

    const handleChange = (field, value) => 
    {
        if (field === "Country") 
        {
            setCountry(value);
        }
        else if (field === "State") 
        {
            setState(value);
        }
        else if (field === "Next_Review")
        {
            value = dayjs(value).format("YYYY-MM-DD HH:mm:ss");
        }
        else if (field === "Risk_Classification") 
        {
            const originalReviewDate = dayjs(formData?.originalNextReview || formData?.Next_Review || dayjs());
            const updatedReviewDate = originalReviewDate.add(value?.months || 0, "month").format("YYYY-MM-DD HH:mm:ss");

            setFormData((previousFormData) => ({
                ...previousFormData,
                [field]: value,
                Next_Review: updatedReviewDate,
                originalNextReview: originalReviewDate.format("YYYY-MM-DD HH:mm:ss")
            }));

            return;
        }

        setFormData((previousFormData) => ({
            ...previousFormData,
            [field]: ["Country", "City", "State"].includes(field) ? value ? value.name : "" : typeof value === "string" ? value.trim() === "" ? null : value : value ? value : null
        }));

        handleValidation(field, value);
    }

    const handleSubmit = (event) => 
    {
        event.preventDefault();
        setIsFormSubmitted(true);

        if (Object.keys(validationErrors).length > 0) 
        {
            return;
        }
        else 
        {
            if (rowType === "Existing") 
            {
                handleSubmitEditedRow(formData);
            }
            else if (Object.keys(formData).length > 0) 
            {
                handleSubmitAddedRow(formData);
            }
            else 
            {
                handleClose();
            }
        }
    }

    return (
        <Modal className = "form-modal entities" show = {show} onHide = {handleClose} centered = {true}>
            <Backdrop
                sx = {{ color: "#fff", position: 'absolute', height: '100%', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open = {isLoading}
            >
                <CircularProgress color = "inherit" />
            </Backdrop>

            <Modal.Header closeButton = {true}>
                <Modal.Title>
                    {rowType === "Existing" ? `Edit ${title}` : `Add ${title}`}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit = {handleSubmit}>
                    <Grid container spacing = {2} className = "align-items-start autocomplete">
                        {columns?.filter((column) => (column.field !== "Created_On" && column.field !== "created_on" && column.field !== "actions"))?.map((column, index) => 
                        {
                            const hasError = isFormSubmitted && validationErrors[column.field] !== undefined;
                            const errorMessage = validationErrors[column.field] || "";

                            return (
                                <Grid size = {{ xs: 3 }} key = {index}>
                                    {column.field === "Business_Unit" ? (
                                        <VirtualizedAutocomplete
                                            isMultiple = {false}
                                            isObject = {true}
                                            isWritable = {true}
                                            filterOn = "Business Unit"
                                            options = {dropdowns["Business_Unit"] || []}
                                            selectedOptions = {formData[column.field] || null}
                                            handleSelectChange = {(filterOn, newValue) => handleChange("Business_Unit", newValue)}
                                        />
                                    ) : column.field === "Captive_Status" ? (
                                        <VirtualizedAutocomplete
                                            isMultiple = {false}
                                            isObject = {false}
                                            isWritable = {true}
                                            filterOn = "Captive Status"
                                            options = {["Captive", "Non-Captive"]}
                                            selectedOptions = {formData[column.field] || null}
                                            handleSelectChange = {(filterOn, newValue) => handleChange("Captive_Status", newValue)}
                                        />
                                    ) : column.field === "City" ? (
                                        <CitySelect
                                            placeHolder = "City"
                                            showFlag = {false}
                                            countryid = {country?.id || 0}
                                            stateid = {state?.id || 0}
                                            defaultValue = {formData[column.field] && { name: formData[column.field] }}
                                            onTextChange = {(event) => handleTextChange("City", event.target.value)}
                                            onChange = {(event) => handleChange("City", event)}
                                        />
                                    ) : column.field === "Contact_Number" ? (
                                        <PhoneInput
                                            ref = {phoneInputRef}
                                            className = "contact-person-mobile"
                                            placeholder = "Contact Info (Mobile No.)"
                                            value = {formData[column.field] || ""}
                                            error = {hasError}
                                            helperText = {hasError ? errorMessage : ""}
                                            onChange = {(value) => handleChange("Contact_Number", value)}
                                            inputComponent = {CustomPhoneInput}
                                        />
                                    ) : column.field === "Country" ? (
                                        <div className = "position-relative">
                                            <CountrySelect
                                                placeHolder = "Country"
                                                showFlag = {false}
                                                defaultValue = {formData[column.field] && { name: formData[column.field] }}
                                                onTextChange = {(event) => handleTextChange("Country", event.target.value)}
                                                onChange = {(event) => handleChange("Country", event)}
                                                autoComplete = "new-password"
                                            />

                                            <IconButton aria-label = "dropdown" className = "dropdown-arrow">
                                                <ArrowDropDownIcon />
                                            </IconButton>
                                        </div>
                                    ) : (title !== "Currency" && column.field === "Currency") ? (
                                        <VirtualizedAutocomplete
                                            isMultiple = {false}
                                            isObject = {true}
                                            isWritable = {true}
                                            filterOn = "Currency"
                                            options = {dropdowns["Currency"] || []}
                                            selectedOptions = {formData[column.field] || null}
                                            handleSelectChange = {(filterOn, newValue) => handleChange("Currency", newValue)}
                                        />
                                    ) : column.field === "Grade" ? (
                                        <VirtualizedAutocomplete
                                            isMultiple = {false}
                                            isObject = {true}
                                            isWritable = {true}
                                            filterOn = "Product Grade"
                                            options = {dropdowns["Grade"] || []}
                                            selectedOptions = {formData[column.field] || null}
                                            handleSelectChange = {(filterOn, newValue) => handleChange("Grade", newValue)}
                                        />
                                    ) : column.field === "KYC_Status" ? (
                                        <VirtualizedAutocomplete
                                            isMultiple = {false}
                                            isObject = {false}
                                            isWritable = {true}
                                            isRequired = {true}
                                            filterOn = "Counter Party Status"
                                            options = {dropdowns["KYC_Status"] || []}
                                            selectedOptions = {formData[column.field] || null}
                                            handleSelectChange = {(filterOn, newValue) => handleChange("KYC_Status", newValue)}
                                        />
                                    ) : column.field === "Next_Review" ? (
                                        <LocalizationProvider dateAdapter = {AdapterDayjs}>
                                            <DesktopDatePicker
                                                inputFormat = "YYYY-MM-DD HH:mm:ss"
                                                label = "Next Review"
                                                disableMaskedInput
                                                className = "form-text-field date-picker w-full"
                                                value = {convertToDayJsObject(formData[column.field]) || dayjs()}
                                                onChange = {(newDate) => handleChange("Next_Review", newDate, true)}
                                                onAccept = {(newDate) => handleChange("Next_Review", newDate, true)}
                                                minDate = {dayjs()}
                                                readOnly = {!isWritable}
                                                slotProps = {{
                                                    actionBar: {
                                                        actions: ["clear"]
                                                    },
                                                    textField: {
                                                        InputProps: {
                                                            size: "small",
                                                            required: true,
                                                            disabled: true
                                                        }
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    ) : column.field === "Risk_Classification" ? (
                                        <VirtualizedAutocomplete
                                            isMultiple = {false}
                                            isObject = {true}
                                            isWritable = {true}
                                            isRequired = {true}
                                            filterOn = "Risk Rating"
                                            options = {dropdowns["Risk_Classification"] || []}
                                            selectedOptions = {formData[column.field] || null}
                                            handleSelectChange = {(filterOn, newValue) => handleChange("Risk_Classification", newValue)}
                                        />
                                    ) : column.field === "State" ? (
                                        <StateSelect
                                            placeHolder = "State"
                                            showFlag = {false}
                                            countryid = {country?.id || 0}
                                            defaultValue = {formData[column.field] && { name: formData[column.field] }}
                                            onTextChange = {(event) => handleTextChange("State", event.target.value)}
                                            onChange = {(event) => handleChange("State", event)}
                                        />
                                    ) : column.field === "Tab" ? (
                                        <VirtualizedAutocomplete
                                            isMultiple = {false}
                                            isObject = {false}
                                            isWritable = {true}
                                            filterOn = "Tab"
                                            options = {["Buying Leg", "Selling Leg"]}
                                            selectedOptions = {formData[column.field] || null}
                                            handleSelectChange = {(filterOn, newValue) => handleChange("Tab", newValue)}
                                        />
                                    ) : column.field === "Type_of_Packaging" ? (
                                        <VirtualizedAutocomplete
                                            isMultiple = {false}
                                            isObject = {true}
                                            isWritable = {true}
                                            filterOn = "Type of Packaging"
                                            options = {dropdowns["Type_of_Packaging"] || []}
                                            selectedOptions = {formData[column.field] || null}
                                            handleSelectChange = {(filterOn, newValue) => handleChange("Type_of_Packaging", newValue)}
                                        />
                                    ) : column.field === "Unit_of_Measurement" ? (
                                        <VirtualizedAutocomplete
                                            isMultiple = {false}
                                            isObject = {true}
                                            isWritable = {true}
                                            filterOn = "UoM"
                                            options = {dropdowns["Unit_of_Measurement"] || []}
                                            selectedOptions = {formData[column.field] || null}
                                            handleSelectChange = {(filterOn, newValue) => handleChange("Unit_of_Measurement", newValue)}
                                        />
                                    ) : (
                                        <div className = "text-field mt-1">
                                            <TextField
                                                label = {column?.field?.replaceAll("_", " ")}
                                                type = {column.type}
                                                className = {`${column?.field === "Name" ? 'name-text-uppercase' : ''}`}
                                                value = {formData[column.field] || ""}
                                                error = {hasError}
                                                helperText = {hasError ? errorMessage : ""}
                                                required = {column?.field === "Name"}
                                                onChange = {(event) => handleChange(column.field, event.target.value)}
                                                slotProps = {{
                                                    input: { min: 0 }
                                                }}
                                                onKeyDown = {(event) => 
                                                {
                                                    if (column.type === "number" && (event?.key === "-" || event?.key === "+")) 
                                                    {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </div>
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>

                    <button ref = {submitButtonRef} type = "submit" className = "hidden"></button>
                </form>
            </Modal.Body>
            <Modal.Footer className = "gap-2">
                <Button variant = "outlined" size = "small" onClick = {handleClose}>
                    Cancel
                </Button>
                <Button variant = "contained" size = "small" onClick = {() => submitButtonRef?.current?.click()}>
                    {rowType === "Existing" ? 'Update' : 'Add'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default memo(Form);