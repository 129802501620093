import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getPermissions } from '../../lib/accessControl';
import { roles } from '../../utility/roles';
import withAuth from '../../lib/withAuth';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import MaterialReactDataTable from '../../components/MaterialReactDataTable';
import Alert from '../../components/Alert';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';

const CounterPartyList = ({ logOut = () => {} }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const navigate = useNavigate();
    const { pathname } = useLocation();

    /* AUTHORIZATION VARIABLES */
    const currentUser = useUser();
    const { isWritable = false } = getPermissions(currentUser?.role, pathname);

    /* SESSION EXPIRY VARIABLES */
    const triggerSessionExpire = useSessionExpire();

    /* DATATABLE VARIABLES */
    const [counterPartyListData, setCounterPartyListData] = useState({});
    const tableRef = useRef();

    /* DATATABLE GLOBAL FILTER VARIABLES */
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    
    useEffect(() =>
    {
        const fetchData = async () => 
        {
            setIsLoading(true);
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");

            await axios({
                method: "post",
                url: "/GetGRCCounterPartyList",
                data: {
                    filter_value: globalFilterValue
                }
            })
            .then((response) => 
            {
                setIsLoading(false);
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const counterPartyListData = data?.counter_party_list_data || {};      
                    setCounterPartyListData(counterPartyListData);
                }
                else
                {
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get Counter Party List Data Api: ", error);
                setIsLoading(false);
                
                const status = error?.response?.status;

                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }
    
        fetchData();

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);
  
    const handleGlobalFilter = async (rowData) =>
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
        
        await axios({
            method: "post",
            url: "/FilterTableData",
            data: { 
                pathname: pathname,
                table_data: rowData,
                filter_value: globalFilterValue
            }
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
    
            if (status === 200) 
            {
                tableRef?.current?.setFilteredData(data || []);
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Filter Table Data Api: ", error);
            setIsLoading(false);
            
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });        
    }

    const handleEditKYCRequest = async (id) =>
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
        
        await axios({
            method: "post",
            url: "/SetKYCRequestFormLock",
            data: {
                uuid: id,
                lock_kyc: true
            }
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status } = response;
    
            if (status === 200) 
            {
                navigate("/kyc/edit-kyc", { state: { isCounterPartyListItem: true, kycId: id } });
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Set KYC Request Form Lock Api: ", error);
            setIsLoading(false);
            
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }
        
    return (
        <div className = "content-container d-flex flex-column container kyc">
            <Alert 
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />

            <Backdrop
                sx = {{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open = {isLoading}
            >
                <CircularProgress color = "inherit" />
            </Backdrop>

            <Typography variant = "h5" gutterBottom className = "page-heading m-0">
               Counter Party List
            </Typography>

            <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <MaterialReactDataTable
                    ref = {tableRef}
                    title = "Counter Party List"
                    isWritable = {isWritable}
                    isLoading = {isLoading}
                    currentUserId = {currentUser?.id}
                    globalFilterValue = {globalFilterValue}
                    reportData = {counterPartyListData}
                    setIsLoading = {setIsLoading}
                    setShowAlert = {setShowAlert}
                    setAlertMessage = {setAlertMessage}
                    setAlertType = {setAlertType}
                    setGlobalFilterValue = {setGlobalFilterValue}
                    handleGlobalFilter = {handleGlobalFilter}
                    handleEditKYCRequest = {handleEditKYCRequest}
                />
            </LocalizationProvider>
        </div>
    );
}

export default withAuth(CounterPartyList)([
    roles[7],
    roles[19]
]);